<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>Edit Medicine</h1>

    </div>
    <div>
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>
  </div>
</div>
<div>
  <form class="example-form" style="max-width: 100%;" #Form="ngForm">
    <div style="display: flex;">
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Medicine</mat-label>
        <input matInput placeholder="Name" required type="text" [(ngModel)]="addForm.productName"
          name="addForm.productName" />
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Brand</mat-label>
        <input matInput placeholder="Brand Name" [(ngModel)]="addForm.Brand" name="addForm.Brand">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Dosage Form</mat-label>
        <mat-select [(ngModel)]="addForm.DosageFom" (ngModelChange)="liquid($event)" name="addForm.DosageFom"
          placeholder="Select" required>
          <mat-option value="Tablets">Tablets</mat-option>
          <mat-option value="Capsules">Capsules</mat-option>
          <mat-option value="Suspensions">Suspensions</mat-option>
          <mat-option value="Liquids">Liquids</mat-option>
          <mat-option value="Topical ointments">Topical ointments</mat-option>
          <mat-option value="Injections">Injections</mat-option>
          <mat-option value="Transdermal patches">Transdermal patches</mat-option>
        </mat-select>
        <!-- <input matInput placeholder="Dossage Form" [(ngModel)]="addForm.dosageform" name="addForm.dosageform"> -->
      </mat-form-field>
      <!-- <mat-form-field *ngIf="addForm?.DosageFom == 'Tablets' || addForm?.DosageFom == 'Capsules' || addForm?.DosageFom == 'Suspensions' || addForm?.DosageFom == 'Liquids' || addForm?.DosageFom == 'Injections' || addForm?.DosageFom == ''" class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Strength<span *ngIf="tab != '' || tab != null">({{tab}})</span></mat-label>
        <input matInput required type="text" [(ngModel)]="addForm.Strength" name="addForm.Strength"
          placeholder="Strength" />
      </mat-form-field> -->
   
      <mat-form-field *ngIf="addForm?.DosageFom == 'Tablets' || addForm?.DosageFom == 'Capsules' || addForm?.DosageFom == 'Suspensions' || addForm?.DosageFom == 'Liquids' || addForm?.DosageFom == 'Injections' || addForm?.DosageFom == ''" class="example-full-width" [floatLabel]="'always'">
        <mat-label>Dose per Kg</mat-label>
        <input matInput type="number" [(ngModel)]="addForm.Strength2" name="addForm.Strength2" placeholder="Dose" />
      </mat-form-field>
    </div>
    <div style="display: flex;">

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Quantity
        </mat-label>
        <input type="number" matInput placeholder="No's" [(ngModel)]="addForm.CurrentQuantity" name="addForm.CurrentQuantity">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Level

        </mat-label>
        <input type="number" matInput placeholder="No's" [(ngModel)]="addForm.ReOrderLevel" name="addForm.ReOrderLevel">
      </mat-form-field>
      <!-- <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Quantity

        </mat-label>
        <input matInput placeholder="No's" type="number" [(ngModel)]="addForm.ReQuantity" name="addForm.ReQuantity">
      </mat-form-field> -->


      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Lot/Batch No.</mat-label>
        <input matInput placeholder="Number" [(ngModel)]="addForm.BatchNo" name="addForm.BatchNo">
      </mat-form-field>
    </div>
    <div style="display: flex; flex-wrap:wrap">


      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Supplier Info
        </mat-label>
        <input matInput placeholder="Supplier Info" [(ngModel)]="addForm.SupplierInfo" name="addForm.SupplierInfo">
      </mat-form-field>
      <mat-form-field appearance="fill" class="example-full-width expire" [floatLabel]="'always'">
        <mat-label>Expiration Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="addForm.ExpirationDate" name="addForm.ExpirationDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Purchase Price</mat-label>
        <input type="number" matInput placeholder="Purchase Price" [(ngModel)]="addForm.PurchasePrice" name="addForm.PurchasePrice"
          required="">
      </mat-form-field>
      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Retail Price</mat-label>
        <input type="number" matInput placeholder="Retail Price" [(ngModel)]="addForm.RetailPrice" name="addForm.RetailPrice"
          required="">
      </mat-form-field>
    </div>
    <div style="display: flex;">


      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Max Retail Discount</mat-label>
        <input  type="number" matInput placeholder="Max Retail Discount" [(ngModel)]="addForm.maxRetailDiscount"
          name="addForm.maxRetailDiscount">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>HSN Code</mat-label>
        <input matInput placeholder="HSN Code" [(ngModel)]="addForm.HSN" name="addForm.HSN">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>SGST</mat-label>
        <input type="number" matInput placeholder="SGST" [(ngModel)]="addForm.SGST" name="addForm.SGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>CGST</mat-label>
        <input type="number" matInput placeholder="CGST" [(ngModel)]="addForm.CGST" name="addForm.CGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Storage Temperatur</mat-label>
        <mat-select [(ngModel)]="addForm.StorageTemperature" name="addForm.StorageTemperature" placeholder="Select">
          <mat-option value="Room Temperature">Room Temperature</mat-option>
          <mat-option value="Refrigerator">Refrigerator</mat-option>
          <mat-option value="Frozen">Frozen</mat-option>

        </mat-select>
        <!-- <input matInput placeholder="Storage Temperature" [(ngModel)]="addForm.storageTemp" name="addForm.storageTemp"> -->
      </mat-form-field>
    </div>
    <!-- <div style="display: flex;">

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Indication for use </mat-label>
        <input matInput placeholder="Indication for use" [(ngModel)]="addForm.indicationuse"
          name="addForm.indicationuse">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Contra-indications </mat-label>
        <input matInput placeholder="Contra-indications" [(ngModel)]="addForm.contraindications"
          name="addForm.contraindications">
      </mat-form-field>
    </div> -->
    <!-- <div style="display: flex;">


     
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Side Effects</mat-label>
        <input matInput placeholder="Side Effects" [(ngModel)]="addForm.sideeffects" name="addForm.sideeffects">
      </mat-form-field>
    </div> -->


    <div style="display: flex;">
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Barcode/SKU</mat-label>
        <input type="number" matInput placeholder="Barcode/SKU" readonly [(ngModel)]="addForm.barCode" name="addForm.barCode">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Location
        </mat-label>
        <input matInput placeholder="Location" [(ngModel)]="addForm.Location" name="addForm.Location">
      </mat-form-field>
    </div>

  </form>
  <div class="modalFooter">
    <p>
      You're about to update. Please review and make any needed changes.
    </p>
    <div>
      <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" mat-raised-button type="submit" [disabled]="Form.invalid"
        (click)="save()">Update</button>

    </div>


  </div>
</div>