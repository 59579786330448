import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ToastComponent } from './shared/toast/toast.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ManagerMainPageModule } from './pages/manager-main/manager-main.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import {MatIconModule} from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DatePipe } from '@angular/common'; 
import {MatInputModule} from '@angular/material/input';
import { DialogDataDialog } from './pages/dialogbox/dialog.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReadydialogComponent } from './pages/manager/readydialog/readydialog.component';
import { FormsModule } from '@angular/forms';



// import { DialogboxComponent } from './pages/dialogbox/dialogbox.component';
const config: SocketIoConfig = {
	 url: 'https://dhor.clinic.api.stageprojects.xyz/', // socket server url;
  //url: 'https://localhost:4002/', // socket server url;
	options: {
   transports: ["polling"],

		// transports: ['websocket']
	}
}
@NgModule({
  declarations: [AppComponent, ToastComponent,DialogDataDialog,ReadydialogComponent],
  imports: [
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ animated: false }),
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    ManagerMainPageModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    // MatDialogModule,
    SocketIoModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // SocketIoModule.forRoot(config)
  ],
  providers: [
    { provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    // AuthguardGuard,
    DialogDataDialog,
    ToastComponent, DatePipe],

   
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents:[MatDialogModule],
})
export class AppModule { }
