<div class="inventory-edit">
  <div class="center-modal-header">
    <div style="flex: 0 0 100%; max-width: 100%">
      <ion-row>
        <ion-col sizeMd="8" class="main-logo-box-pres">
          <svg
            class="color-item"
            xmlns="http://www.w3.org/2000/svg"
            width="479"
            height="174"
            viewBox="0 0 479 174"
            fill="none"
          >
            <path
              d="M473.328 144.091L440.468 154.074L323.913 2.53455L356.773 -7.44753L473.328 144.091Z"
              fill="#01C885"
            />
            <path
              d="M1 51L57 133H476L382.5 3H1"
              stroke="black"
              stroke-width="3"
            />
          </svg>
          <svg
            class="small-icon-box-pre"
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="133"
            viewBox="0 0 50 133"
            fill="none"
          >
            <path d="M50 133H-249.241L-344 0H-44.7595L50 133Z" fill="#012641" />
            <path d="M28 113H-272L-367 -33H-67L28 113Z" fill="#01C885" />
          </svg>
          <div class="content-prescritption">
            <img
              *ngIf="patientData?.clientId?.image?.url"
              [src]="patientData?.clientId?.image?.url"
            />
            <img
              *ngIf="!patientData?.clientId?.image?.url"
              src="../../../assets/icon/logo.png"
            />
            <!-- <img src="../../../assets/icon/logo.png"> -->
          </div>
        </ion-col>
        <ion-col sizeMd="4" class="doc-mob-cont">
          <p class="doctor-name-pres">{{ doctorData?.name }}</p>
          <p class="doctor-mob-pres">{{ doctorData?.mobile }}</p>
          <p class="doctor-mob-pres">
            <ion-icon name="calendar-outline"></ion-icon
            ><span>{{
              patientData?.appoinmentTime | date : "dd-MMM-yyyy"
            }}</span>
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="146"
            viewBox="0 0 73 146"
            fill="none"
          >
            <path d="M397 146H97L2 0H302L397 146Z" fill="#EBEBEB" />
            <path d="M56 28H37.1116L0 -29H18.8884L56 28Z" fill="#01C885" />
          </svg>
        </ion-col>
      </ion-row>
    </div>

    <button class="close-button" (click)="closedialog()">
      <img src="../assets/close-icon.svg" />
    </button>
  </div>
</div>
<div>
  <ion-row class="canvarow">
    <ion-col size="12">
      <ion-row>
        <ion-col sizeMd="6">
          <div class="flex-class-pres-img">
            <div class="heading-title-pre"><p>Patient Name:</p></div>
            <div class="heading-title-conten">
              <p>
                <span>{{ patientData?.dogs?.name }}</span>
              </p>
            </div>
          </div>
        </ion-col>
        <ion-col sizeMd="6">
          <div class="flex-class-pres-img">
            <div class="heading-title-pre"><p>Father / Spouse:</p></div>
            <div class="heading-title-conten">
              <p>
                <span>{{ patientData?.dogs?.father_spouseName }}</span>
              </p>
            </div>
          </div>
        </ion-col>
        <ion-col sizeMd="4">
          <div class="flex-class-pres-img">
            <div class="heading-title-pre"><p>Mobile:</p></div>
            <div class="heading-title-conten">
              <p>
                <span>{{ patientData?.ownerId?.mobile }}</span>
              </p>
            </div>
          </div>
        </ion-col>
        <ion-col sizeMd="4">
          <div class="flex-class-pres-img">
            <div class="heading-title-pre"><p>Age:</p></div>
            <div class="heading-title-conten">
              <p>
                <span>{{ patientData?.dogs?.age }}</span>
              </p>
            </div>
          </div>
        </ion-col>
        <ion-col sizeMd="4">
          <div class="flex-class-pres-img">
            <div class="heading-title-pre"><p>Gender:</p></div>
            <div class="heading-title-conten">
              <p>
                <span>{{ patientData?.dogs?.gender }}</span>
              </p>
            </div>
          </div>
        </ion-col>
        <!-- <ion-col sizeMd="6">
          <div class="flex-class-pres-img">
            <div class="heading-title-pre"><p>Date:</p></div>
          </div>
        </ion-col> -->
      </ion-row>
    </ion-col>
    <ion-col sizeMd="8" class="rxdesign-box-main">
      <p class="Rxdesign">R<span>x</span></p>
      <ng-container *ngIf="patientData?.prescriptionImage?.length === 0">
        <div
          _ngcontent-xdx-c337=""
          class="addedMeds"
          *ngIf="
            drugData?.length > 0 && patientData?.clientId?.type === 'Homeopathy'
          "
        >
          <!-- <h1>Drugs - Total Bottle ({{ bottles?.length }})</h1> -->
          <ng-container *ngFor="let bottle of bottles">
            <div style="width: 100%; margin-bottom: 7px">
              <div>
                <p>{{ bottle.bottle }}</p>
              </div>
              <div class="medsCard">
                <ng-container *ngFor="let drugs of drugData">
                  <ng-container *ngIf="drugs?.bottle == bottle?.bottle">
                    <div>
                      <div>
                        <p>{{ drugs?.name }}</p>
                        <p>
                          ({{ drugs?.strengthML }}
                          <ng-container *ngIf="drugs?.strengthML != 'Q'"
                            >p</ng-container
                          >) , {{ drugs?.repeat }} for
                          {{ drugs?.duration }} days in
                          {{ drugs?.timeoftheday }} ,
                          {{ drugs?.tobeTaken }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          _ngcontent-xdx-c337=""
          class="addedMeds"
          *ngIf="
            drugData?.length > 0 && patientData?.clientId?.type === 'Allopathy'
          "
        >
          <ng-container *ngFor="let drugs of drugData">
            <div class="meds-data-box">
              <!-- <div>
              <p>Quantity : {{ drugs?.quantity }}</p>
            </div> -->
              <div class="medsCard">
                <div>
                  <div>
                    <p>{{ drugs?.name }}</p>
                    <p>
                      ({{ drugs?.strengthML }}
                      <ng-container *ngIf="drugs?.strengthML != 'Q'"
                        >p</ng-container
                      >) , {{ drugs?.repeat }} for {{ drugs?.duration }} days in
                      {{ drugs?.timeoftheday }} , {{ drugs?.tobeTaken }}, Qty
                      {{ drugs?.quantity }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- <div *ngIf="patientData?.treatmentNotes">
            <div *ngFor="let notes of patientData?.treatmentNotes">{{notes}}</div>
          
          </div> -->
        </div>
      </ng-container>

      <div
        style="
          background-image: url('../../../assets/Caduceus1.png');
          background-size: 68% auto;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 0px;
        "
      >
        <canvas #canvas34 id="canvas34" width="500" height="600"></canvas>
      </div>
    </ion-col>
    <ion-col
      sizeMd="4"
      class="childPadding"
      *ngIf="
        
        patientData?.tentativeDiagnosis != '' ||
        patientData?.clinicalExamination != '' ||
        patientData?.symptoms != '' ||
        patientData?.treatmentNotes != '' ||
        patientData?.prescriptionImage != '' ||
        patientData?.heartRate != '' ||
        patientData?.temperature != '' ||
        patientData?.weight != ''
      "
    >
      <div >
        <div _ngcontent-xdx-c337="" class="TreatmentLog child">
          <div _ngcontent-xdx-c337="" class="log">
            <div
              _ngcontent-xdx-c337=""
              class="vital-logs-data"
              *ngIf="
                patientData?.heartRate != '' ||
                patientData?.temperature != '' ||
                patientData?.weight != ''

              "
            >
          
              <p _ngcontent-xdx-c337="" class="previous-log-table-vitals">
                <span
                  _ngcontent-xdx-c337=""
                  class="previous-log-table-vitals-log"
                  >Vitals log </span
                ><span
                  _ngcontent-xdx-c337=""
                  class="previous-log-table-organization"
                >
                </span>
              </p>
              <div class="vital-log-innerscroll">
                <div _ngcontent-xdx-c337="" class="vitallogs">
                  <div class="weight-info">
                    <div
                      class="weight-info-main"
                      *ngIf="patientData?.weight != ''"
                    >
                      <div class="weight-info-data">
                        <h6>
                          <!-- {{ data1.time | date : "dd-MM-yyyy HH:mm" }} -->
                          Weight: {{ patientData?.weight[0]?.value }} Kg
                        </h6>
                        <!-- <h4 *ngIf="i < 3">{{ data1?.value }}Kg</h4> -->
                      </div>
                      <!-- <div class="weight-info-data">
                  <h6>2:35 PM, 1/2/23</h6>
                  <h4>27kg</h4>
                </div>
                <div class="weight-info-data">
                  <h6>2:35 PM, 1/2/23</h6>
                  <h4>27kg</h4>
                </div> -->
                    </div>
                  </div>
                </div>
                <div _ngcontent-xdx-c337="" class="vitallogs">
                  <div class="weight-info">
                    <div
                      class="weight-info-main"
                      *ngIf="patientData?.temperature != ''"
                    >
                      <div class="weight-info-data">
                        <h6>
                          <!-- {{ data2.time | date : "dd-MM-yyyy HH:mm" }} -->
                          Temp: {{ patientData?.temperature[0]?.value
                          }}<span>&#176;</span> C
                        </h6>
                        <!-- <h4 *ngIf="i < 3">
                          {{ data2.value }}<span>&#176;</span> C
                        </h4> -->
                      </div>
                      <!-- <div class="weight-info-data">
                  <h6>2:35 PM, 1/2/23</h6>
                  <h4>27kg</h4>
                </div>
                <div class="weight-info-data">
                  <h6>2:35 PM, 1/2/23</h6>
                  <h4>27kg</h4>
                </div> -->
                    </div>
                  </div>
                </div>
                <div _ngcontent-xdx-c337="" class="vitallogs">
                  <div class="weight-info">
                    <div
                      class="weight-info-main"
                      *ngIf="patientData?.heartRate != ''"
                    >
                      <div class="weight-info-data">
                        <h6>
                          <!-- {{ data3?.time | date : "dd-MM-yyyy HH:mm" }} -->
                          Heart Rate: {{ patientData?.heartRate[0]?.value }} bpm
                        </h6>
                        <!-- <h4 *ngIf="i < 3">{{ data3?.value }}/bpm</h4> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-xdx-c337="" class="vitallogs">
                  <div class="weight-info">
                    <div
                      class="weight-info-main"
                      *ngIf="patientData?.oxygen != ''"
                    >
                      <div class="weight-info-data">
                        <h6>
                          Oxygen: {{ patientData?.oxygen[0]?.value }} mmHg
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div _ngcontent-xdx-c337="" class="vitallogs">
                  <div class="weight-info">
                    <div
                      class="weight-info-main"
                      *ngIf="patientData?.bloodPressure != ''"
                    >
                      <div class="weight-info-data">
                        <h6>
                          Blood Pressure:
                          {{ patientData?.bloodPressure[0]?.value }} %SpO2
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .................................... -->

            <div
              _ngcontent-xdx-c337=""
              class="vital-logs-data"
              *ngIf="patientData?.treatmentNotes != ''"
            >
              <p _ngcontent-xdx-c337="" class="previous-log-table-vitals">
                <span
                  _ngcontent-xdx-c337=""
                  class="previous-log-table-vitals-log"
                  >Treatment Notes</span
                ><span
                  _ngcontent-xdx-c337=""
                  class="previous-log-table-organization"
                >
                </span>
              </p>
              <div class="vital-log-innerscroll">
                <div
                  _ngcontent-xdx-c337=""
                  class="vitallogs"
                  *ngFor="let data of patientData?.treatmentNotes"
                > 
                  <div class="weight-info">
                    <div class="weight-info-main">
                      <div class="weight-info-data">
                        <h6>
                          {{data}}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <!-- .......................... -->
            <div
              _ngcontent-xdx-c337=""
              class="vital-logs-data"
              *ngIf="patientData?.symptoms != ''"
            >
              <p _ngcontent-xdx-c337="" class="previous-log-table-vitals">
                <span
                  _ngcontent-xdx-c337=""
                  class="previous-log-table-vitals-log"
                  >Symptoms </span
                ><span
                  _ngcontent-xdx-c337=""
                  class="previous-log-table-organization"
                >
                </span>
              </p>
              <div class="vital-log-innerscroll">
                <div
                  _ngcontent-xdx-c337=""
                  class="vitallogs"
                  *ngFor="let data of patientData?.symptoms"
                >
                  <div class="weight-info">
                    <div class="weight-info-main">
                      <div class="weight-info-data">
                        <h6>
                          {{ data }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

    

            <ion-row>
              <!-- tentative diagnosis -->
              <ion-col *ngIf="patientData?.tentativeDiagnosis != ''" size="12">
                <div
                  _ngcontent-xdx-c337=""
                  class="Symptoms-bg-box add-box divide-box"
                  *ngIf="patientData?.tentativeDiagnosis != ''"
                >
                  <div _ngcontent-xdx-c337="" class="sym-inner-box">
                    <p _ngcontent-xdx-c337="" class="sym-text-value">
                      Tentative diagnosis
                    </p>
                    <div _ngcontent-xdx-c337="" class="dot-box"></div>
                    <p _ngcontent-xdx-c337="" class="org-text-value"></p>
                  </div>

                  <div class="chips">
                    <div
                      *ngFor="
                        let data of patientData?.tentativeDiagnosis;
                        let i = index
                      "
                    >
                      <h5>{{ data }}<button class="close-btn-del"></button></h5>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>

            <div class="extraSpacing"></div>
            <!-- </div> -->
            <div _ngcontent-xdx-c337="" class="report"></div>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col sizeMd="8"> </ion-col>
    <ion-col sizeMd="4">
      <div class="signature-stamp-class">
        <div style="margin-right: 30px">
          <img *ngIf="doctorData?.signature" [src]="doctorData?.signature" />
        </div>
        <div style="margin-right: 30px">
          <img *ngIf="doctorData?.stamp" [src]="doctorData?.stamp" />
        </div>
      </div>
      <!-- <p class="sign-stam">Signature & Stamp</p> -->
    </ion-col>
  </ion-row>
  <ion-row class="footer-address">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="804"
      height="65"
      viewBox="0 0 804 65"
      fill="none"
    >
      <path d="M329.782 154H79.3146L0 8H250.467L329.782 154Z" fill="#01C885" />
      <path d="M804 247H311.309L155.29 20H647.981L804 247Z" fill="#004D8B" />
      <path
        d="M648.71 57H632.941L601.956 0H617.726L648.71 57Z"
        fill="#01C885"
      />
    </svg>
    <p>Branch Address : {{ patientData?.branchId?.branch }}</p>
  </ion-row>
  <div class="modalFooter">
    <div>
      <!-- revoved [disabled]="signaturePad.isEmpty()" -->
      <button [disabled]="spinner" (click)="saveSignature()">
        Save<ion-item *ngIf="spinner" class="spinneraddprescript">
          <ion-spinner name="crescent"></ion-spinner>
        </ion-item>
      </button>
      <button class="clear" (click)="clearSignature()">Clear</button>
    </div>
  </div>
</div>
