import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-accessories',
  templateUrl: './add-accessories.component.html',
  styleUrls: ['./add-accessories.component.scss'],
})
export class AddAccessoriesComponent implements OnInit {
  addForm: any;
  userProfile:any=localStorage.getItem('userProfile');

  constructor(public dialogRef: MatDialogRef<AddAccessoriesComponent>,
    private toast: ToastComponent,
    private crudService: CrudService
  ) {
    this.userProfile = JSON.parse(this.userProfile);
    var barcode = Math.floor(100000000 + Math.random() * 900000000);
    this.crudService.branchId = localStorage.getItem('branchId')

    this.addForm = {
      productName: "",
      brand: "",
      type: "",
      uses: "",
      currentQuantity: "",
      reorderLevel: "",
      reQuantity: "",
      material: "",
      color: "",
      purchasePrice: "",
      retailPrice: "",
      maxRetailDiscount: "",
      barCode: "",
      location: "",
      HSN:"",
      SGST:"",
      CGST:"",
      Inventorytype:"Accessories",
      clientId: this.userProfile?.clientId?._id,
      branchId: this.crudService?.branchId
    }
    this.addForm.barCode = barcode
  }

  ngOnInit() { }
  closedialog() {
    this.dialogRef.close();
  }
  save() {
    //console.log('dataaaaaa')
    this.crudService.add(this.addForm, environment.api.accessories).then((res) => {
      this.toast.presentToast("Created Successfull")
      this.dialogRef.close()
      this.addForm.reset()

    })
  }


}

