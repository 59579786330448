import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-food',
  templateUrl: './add-food.component.html',
  styleUrls: ['./add-food.component.scss'],
})
export class AddFoodComponent implements OnInit {
addForm:any;
  userProfile:any=localStorage.getItem('userProfile');

  constructor(
    public dialogRef: MatDialogRef<AddFoodComponent>,
    private toast:ToastComponent,
    private crudService:CrudService

  ) {

      this.userProfile = JSON.parse(this.userProfile);
      this.crudService.branchId = localStorage.getItem('branchId')

      var barcode = Math.floor(100000000 + Math.random() * 900000000);
      this.addForm={
      productName:"",
      brand:"",
      weight:"",
      strength:"",
      lifeStage:"",
      nutritionalInformation:"",
      ingredients:"",
      type:"",
      HSN:"",
      SGST:"",
      CGST:"",
      Inventorytype:"Food",

      proteinSource:"",
      flavour:"",
      currentQuantity:"",
      reorderLevel:"",
      reQuantity:"",
      batchNo:"",
      purchasePrice:"",
      retailPrice:"",
      maxRetailDiscount:"",
      barCode:"",
      location:"",
        clientId: this.userProfile?.clientId?._id,
      branchId: this.crudService?.branchId
    }
    this.addForm.barCode = barcode
   }

  ngOnInit() {}
  closedialog(){
    this.dialogRef.close();
  }

  save(){

    this.crudService.add(this.addForm,environment.api.food).then((res)=>{
      this.toast.presentToast("Created Successfull")
    this.dialogRef.close()
    this.addForm.reset()

    })

}
}
