import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad from "signature_pad";
import { CrudService } from 'src/app/services/crud/crud.service';
import { FileUploadService } from 'src/app/services/fileupload/file-upload.service';
import { FileUpload } from 'src/app/shared/classes/file-upload';
@Component({
  selector: 'app-compare-prescription',
  templateUrl: './compare-prescription.component.html',
  styleUrls: ['./compare-prescription.component.scss'],
})
export class ComparePrescriptionComponent implements OnInit {
  userProfile: any = localStorage.getItem('userProfile');

  constructor(
    public dialogRef: MatDialogRef<ComparePrescriptionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private CrudService: CrudService,
    private FileUploadService: FileUploadService 

  ) { 
    this.userProfile = JSON.parse(this.userProfile);

  }

  ngOnInit() {}
  closedialog(){
    this.dialogRef.close();
  }
}
