import { Injectable } from '@angular/core';
// import { FileUpload } from 'src/app/shared/classes/file-upload';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/core/constant';
// import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { LoadingserviceService } from '../loading/loadingservice.service';
declare var AWS: any;


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  apiUrl: string = AppSettings.API_ENDPOINT;
  headers: any;

  loadingFlag: boolean = false;
  uploadPercentage: any = '';
  constructor(public http: HttpClient,
    public loadingService: LoadingserviceService,
    public snackbarCmp: ToastComponent,
  ) {

  }

  private basePath: string = '/';


async uploadFileToS3(upload: any, subfolderPath: string) {
  console.log(upload, subfolderPath);
  let date = new Date();
  let name = '';
  name = name + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + '-' + date.getMilliseconds() + (upload.file ? upload.file.name : '');
  
  let bucket = new AWS.S3({
    accessKeyId: 'AKIAWX5OXDFH3F3DE34O',
    secretAccessKey: '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',
    region: 'ap-southeast-1'
  });

  const params = {
    Bucket: 'dhorandjoy',
    Key: subfolderPath + name,
    Body: upload.file ? upload.file : upload,
    ContentType: upload.type,
    ACL: "public-read-write"
  };

  return new Promise((resolve, reject) => {
    this.loadingFlag = true;
    let self = this;
    bucket.upload(params).on('httpUploadProgress', function (evt: any) {
      self.uploadPercentage = ((evt.loaded / evt.total) * 100).toFixed(0);
      upload.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
    }).send(function (err: any, data: any) {
      if (err) {
        self.loadingFlag = false;
        reject(err);
      }
      if (data) {
        resolve({ url: data.Location });
        self.loadingFlag = false;
      }
    });
  });
}


  pushUpload(upload:any, subfolderPath: string) {
    console.log(upload, subfolderPath)
    let date = new Date();
    let name = '';
    name = name + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + '-' + date.getMilliseconds() + (upload.file ? upload.file : '');
    //console.log(upload.file,'upload');
    let bucket = new AWS.S3(
      {
        accessKeyId: 'AKIAWX5OXDFH3F3DE34O',
        secretAccessKey: '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',
        region: 'ap-southeast-1'
      }
    );

    const params = {
      Bucket: 'dhorandjoy',
      Key: 'dhorandjoy' + subfolderPath + name,
      Body: upload.file ? upload.file : upload,
      ContentType: upload.type,
      ACL: "public-read-write"
    };


    return new Promise((resolve, reject) => {
      this.loadingFlag = true;
      let self = this;
      bucket.upload(params).on('httpUploadProgress', function (evt:any) {
        self.uploadPercentage = ((evt.loaded / evt.total) * 100).toFixed(0);;
        //console.log(self.uploadPercentage)
        upload.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
      }).send(function (err:any, data:any) {
        if (err) {
          //console.log('There was an error uploading your file: ', err);
          self.loadingFlag = false;
          reject(err);
        }
        if (data) {
          //console.log('Successfully uploaded file.', data);
          resolve({ url: data.Location });
          self.loadingFlag = false;
        }
      });
    });
  }


  // deleteUpload(upload: FileUpload, subfolderPath: string) {
  //   return new Promise((resolve, reject) => {
  //     resolve(true);
  //   });
  // }
  // save(data: any) {
  //   //console.log(data, 'service')
  //   this.loadingService.showLoading();
  //   let addUSerURl = this.apiUrl + 'projectmedia'
  //   return new Promise((resolve, reject) => {
  //     this.http.post(addUSerURl, data, { headers: this.headers }).subscribe((res: any) => {
  //       resolve(res)
  //       this.loadingService.hideLoading();
  //     }, (err) => {
  //       this.loadingService.hideLoading();
  //       this.snackbarCmp.toastError(err.error.message);
  //       reject(err)
  //     })
  //   })
  // }

  // get() {
  //   this.loadingService.showLoading();
  //   let getUserData = this.apiUrl + 'projectmedia';
  //   return new Promise((resolve, reject) => {
  //     this.http.get(getUserData, { headers: this.headers }).subscribe((res: any) => {
  //       resolve(res)
  //       this.loadingService.hideLoading();
  //     }, (err) => {
  //       this.loadingService.hideLoading();
  //       this.snackbarCmp.toastError(err.error.message);
  //       reject(err)
  //     })
  //   })
  // }
  // delete(id:any){
  //   //console.log('username',id)
  //   this.loadingService.showLoading();
  //   let deleteUserurl=this.apiUrl+'projectmedia'+'/'+id
  //   return new Promise((resolve,reject)=>{
  //     this.http.delete(deleteUserurl,{headers:this.headers}).subscribe((res:any)=>{
  //     resolve(res)
  //     this.loadingService.hideLoading()
  //     },(err)=>{
  //       this.loadingService.hideLoading();
  //       this.snackbarCmp.toastError(err.error.message);
  //       reject(err)
  //     })
  //   })
  // }

  ///for multiple img
  pushUploadmultiple(files: any, subfolderPath: string): Promise<any[]> {
    const promises: Promise<any>[] = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(this.uploadFile(files[i], subfolderPath, i));
    }

    return Promise.all(promises);
  }

  private uploadFile(file: any, subfolderPath: string, index: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let date = new Date();
      let name = '';
      name = name + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + '-' + date.getMilliseconds() + file.name;

      let bucket = new AWS.S3(
        {
          accessKeyId: 'AKIAWX5OXDFH3F3DE34O',
          secretAccessKey: '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',
          region: 'ap-southeast-1'
        }
      );

      const params = {
        Bucket: 'dhorandjoy',
        Key: `dhorandjoy${subfolderPath}${name}`,
        Body: file,
        ContentType: file.type,
        ACL: "public-read-write"
      };

      this.loadingFlag = true;
      let self = this;

      bucket.upload(params).on('httpUploadProgress', function (evt: any) {
        self.uploadPercentage = ((evt.loaded / evt.total) * 100).toFixed(0);
        file['progress'] = self.uploadPercentage;
      }).send(function (err: any, data: any) {
        if (err) {
          self.loadingFlag = false;
          reject(err);
        }
        if (data) {
          resolve({ url: data.Location });
          self.loadingFlag = false;
        }
      });
    });
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string, subfolderPath: string) {
    let folderPath = this.basePath + subfolderPath;

    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }



  


}
