

import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrudService } from 'src/app/services/crud/crud.service';
import { FileUploadService } from 'src/app/services/fileupload/file-upload.service';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
@Component({
  selector: 'app-webcam-component',
  templateUrl: './webcam-component.component.html',
  styleUrls: ['./webcam-component.component.scss'],
})
export class WebcamComponentComponent implements OnInit {
  currentUpload: FileUpload
  userProfile: any = localStorage.getItem('userProfile');
camera:boolean=false
galery:boolean=false
selectedFiles: any

  constructor(public dialogRef: MatDialogRef<WebcamComponentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private CrudService: CrudService,
    private FileUploadService: FileUploadService,
    private toast:ToastComponent


  ) {
    this.userProfile = JSON.parse(this.userProfile);
    //console.log(this.data,'datatat')


  }
  public pictureTaken = new EventEmitter<WebcamImage>();
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }
  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }
  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }
  handleImage(webcamImage: WebcamImage): void {

    let data:any = this.DataURIToBlob(webcamImage?.imageAsDataUrl)
    this.currentUpload = data;
    //console.log(this.currentUpload, 'this.currentUpload')

    this.FileUploadService.pushUpload(this.currentUpload, '/appointment/updatePresImage/' + this.userProfile?._id).then((res: any) => {
      //console.log(res,'res')
      let data1 = {
        "prescriptionImage": res?.url,
        "branchId": this.CrudService?.branchId,
        "clientId": this.userProfile?.clientId?._id,

      }
      //  return
      this.CrudService.webcamedit(data1, this.data?.id, 'appointment/updatePresImage').then((res: any) => {

        this.closedialog()

      })


    });

    console.info('received webcam image', webcamImage);
    this.pictureTaken.emit(webcamImage);
  }
  cameraWasSwitched(deviceId: string): void {
    //console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  closedialog() {
    this.dialogRef.close();
  }
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    //console.log("byteString", byteString, "byteString")
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
    let a: any = new Blob([ia], { type: mimeString })
    //console.log(a, "hehehe")
    return new Blob([ia], { type: mimeString })
  }
  camer(){
    this.camera =true;
  }

  onFileInputclinic(event: any) {
    this.camera = false
    this.selectedFiles = event.target.files;
    if (this.selectedFiles[0]?.type === 'image/jpeg' || this.selectedFiles[0]?.type === 'image/png') {

      let file = this.selectedFiles.item(0);
      this.currentUpload = new FileUpload(file);
      this.uploadImageclinic()
    } else {
      this.toast.presentToast("Only JPG and PNG files are allowed.")

    }
  }
  uploadImageclinic() {
    this.FileUploadService.pushUpload(this.currentUpload, '/appointment/updatePresImage/' + this.userProfile?._id).then((res: any) => {
      let data1 = {
        "prescriptionImage": res?.url,
        "branchId": this.CrudService?.branchId,
        "clientId": this.userProfile?.clientId?._id,
      }
      this.CrudService.webcamedit(data1, this.data?.id, 'appointment/updatePresImage').then((res: any) => {
        this.closedialog()
      })
    });
  }
}
