<div *ngIf="data.title == 'inventory'">

  <app-inventory-edit></app-inventory-edit>
</div>
<div *ngIf="data.title == 'food'">
  <app-food-edit></app-food-edit>
</div>
<div *ngIf="data.title == 'accessories'">
  <app-accessories-edit></app-accessories-edit>
</div>
<div *ngIf="data.title == 'AddInventory'">
  <app-add-inventory></app-add-inventory>
</div>
<div *ngIf="data.title == 'AddFood'">
  <app-add-food></app-add-food>
</div>
<div *ngIf="data.title == 'AddAccessories'">
  <app-add-accessories></app-add-accessories>
</div>
<div *ngIf="data.title == 'AddNewInventory'">

  <div class="inventory-edit">
    <div class="center-modal-header">

      <div>
        <h1>Adding Stock For {{addForm?.productName}}</h1>

      </div>
      <div>
        <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
      </div>
    </div>
  </div>
  <div class="formSize">
    <form class="example-form" style="max-width: 100%;" #Form="ngForm">

      <div style="display: flex; ">

        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Additional Quantity</mat-label>
          <input matInput placeholder="Additional Quantity" [(ngModel)]="addForm.addQuantity"
            name="addForm.addQuantity">
        </mat-form-field>

        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Free Samples</mat-label>
          <input matInput placeholder="Free Samples" [(ngModel)]="addForm.freeSamples" name="addForm.freeSamples">
        </mat-form-field>
        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Lot/Batch No.</mat-label>
          <input matInput placeholder="Number" [(ngModel)]="addForm.BatchNo" name="addForm.BatchNo">
        </mat-form-field>


        <mat-form-field appearance="fill" class="example-full-width" style="min-width: 153px;"  [floatLabel]="'always'">
          <mat-label>Expiration Date</mat-label>
          <input matInput [matDatepicker]="picker1" [(ngModel)]="addForm.ExpirationDate" name="addForm.ExpirationDate">
          <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

      </div>
      <div style="display: flex;">

        <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
          <mat-label>Purchase Price</mat-label>
          <input matInput placeholder="Rs" [(ngModel)]="addForm.PurchasePrice" name="addForm.PurchasePrice" required="">
        </mat-form-field>
        <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
          <mat-label>Retail Price</mat-label>
          <input matInput placeholder="Rs" [(ngModel)]="addForm.RetailPrice" name="addForm.RetailPrice" required="">
        </mat-form-field>

        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Barcode/SKU</mat-label>
          <input matInput placeholder="Code" [(ngModel)]="addForm.BarCode" name="addForm.BarCode">
        </mat-form-field>

      </div>


    </form>
    <div class="modalFooter">
      <p>
        The additional stock will be live post administrator's approval
      </p>
      <div>
        <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>
        <button class="modalPrimaryBt" mat-raised-button type="submit" [disabled]="Form.invalid"
          (click)="save()">Request to add</button>

      </div>


    </div>
  </div>
</div>
<div *ngIf="data.title == 'AddNewFood'">
  <div class="inventory-edit">
    <div class="center-modal-header">

      <div>
        <h1>Adding Stock For {{addForm?.productName}}</h1>

      </div>
      <div>
        <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
      </div>
    </div>
  </div>
  <div class="formSize">
    <form class="example-form" style="max-width: 100%;" #Form1="ngForm">
      <div style="display: flex;">

        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Additional Quantity</mat-label>
          <input matInput placeholder="Additional Quantity" [(ngModel)]="addFood.addQuantity"
            name="addFood.addQuantity">
        </mat-form-field>
        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Free Samples</mat-label>
          <input matInput placeholder="Free Samples" [(ngModel)]="addFood.freeSamples" name="addFood.freeSamples">
        </mat-form-field>

        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Lot/Batch No.</mat-label>
          <input matInput placeholder="No." [(ngModel)]="addFood.batchNo" name="addFood.batchNo">
        </mat-form-field>

      </div>


      <div style="display: flex;">



        <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
          <mat-label>Purchase Price</mat-label>
          <input matInput placeholder="Rs" [(ngModel)]="addFood.purchasePrice" name="addFood.purchasePrice" required="">
        </mat-form-field>


        <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
          <mat-label>Retail Price</mat-label>
          <input matInput placeholder="Rs" [(ngModel)]="addFood.retailPrice" name="addFood.retailPrice" required="">
        </mat-form-field>




        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Barcode/SKU</mat-label>
          <input matInput placeholder="No." [(ngModel)]="addFood.barCode" name="addFood.barCode">
        </mat-form-field>



      </div>

    </form>
    <div class="modalFooter">
      <p>
        The additional stock will be live post administrator's approval
      </p>
      <div>
        <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>
        <button class="modalPrimaryBt" mat-raised-button type="submit" [disabled]="Form1.invalid"
          (click)="saveFood()">Request to add</button>

      </div>


    </div>
  </div>
</div>
<div *ngIf="data.title == 'AddNewAccessories'">
  <div class="inventory-edit">
    <div class="center-modal-header">

      <div>
        <h1>Adding Stock For {{addForm?.productName}}</h1>

      </div>
      <div>
        <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
      </div>
    </div>
  </div>
  <div class="formSize">
    <form class="example-form" style="max-width: 100%;" #Form2="ngForm">




      <div style="display: flex;">
        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Additional Quantity</mat-label>
          <input matInput placeholder="Additional Quantity" [(ngModel)]="addAccess.addQuantity"
            name="addAccess.addQuantity">
        </mat-form-field>
        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Free Samples</mat-label>
          <input matInput placeholder="Free Samples" [(ngModel)]="addAccess.freeSamples" name="addAccess.freeSamples">
        </mat-form-field>
        <mat-form-field class="example-full-width mando">
          <mat-label>Purchase Price</mat-label>
          <input matInput placeholder="Rs" [(ngModel)]="addAccess.purchasePrice" name="addAccess.purchasePrice" required="">
        </mat-form-field>

        <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
          <mat-label>Retail Price</mat-label>
          <input matInput placeholder="Rs" [(ngModel)]="addAccess.retailPrice" name="addAccess.retailPrice" required="">
        </mat-form-field>


      </div>
      <div style="display: flex;">


        <mat-form-field class="example-full-width" [floatLabel]="'always'">
          <mat-label>Barcode/SKU</mat-label>
          <input matInput placeholder="No." [(ngModel)]="addAccess.barCode" name="addAccess.barCode">
        </mat-form-field>



      </div>

    </form>
    <div class="modalFooter">
      <p>
        The additional stock will be live post administrator's approval
      </p>
      <div>
        <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>
        <button class="modalPrimaryBt" mat-raised-button type="submit" [disabled]="Form2.invalid"
          (click)="saveAccess()">Request to add</button>

      </div>


    </div>
  </div>
</div>
