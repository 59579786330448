<div class="inventory-edit">
  <div class="center-modal-header">
    <div>
      <h1>Add Prescription</h1>
    </div>
    <div style="padding-top: 6px; padding-right: 7px">
      <button class="close-button" (click)="closedialog()">
        <img src="../assets/close-icon.svg" />
      </button>
    </div>
  </div>
</div>
<div>
  <div style="display: flex; justify-content: center;
  align-items: center;">
    <div *ngIf="data?.new">

      <button class="browse-btn browse-btn-addpres browse-btn-addpres-head" (click)="fileInput.click()">Gallery</button>

      <input
        #fileInput
        id="fileInput"
        type="file"
        (change)="onFileInputclinic($event)"
        style="display: none"
      />

      <button class="browse-btn browse-btn-addpres browse-btn-addpres-head" *ngIf="!camera" (click)="camer()">Camera</button>
    </div>
    <div *ngIf="!data?.new && !camera">
      <webcam
        [height]="500"
        [width]="500"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"
      ></webcam>
      <br />
    </div>
    <div *ngIf="data?.new && camera">
      <webcam
        [height]="500"
        [width]="500"
        [trigger]="triggerObservable"
        (imageCapture)="handleImage($event)"
        *ngIf="showWebcam"
        [allowCameraSwitch]="allowCameraSwitch"
        [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions"
        (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"
      ></webcam>
      <br />
    </div>
  </div>
  <h4 *ngIf="errors.length > 0">Messages:</h4>
  <ul *ngFor="let error of errors">
    <li>{{ error | json }}</li>
  </ul>

  <div class="modalFooter">
    <div *ngIf="data?.new && camera">
      <button class="webcam" (click)="triggerSnapshot()">
        <img src="../assets/Group123.svg" />
      </button>

      <div
        class="actionBtn"
        (click)="showNextWebcam(true)"
        title="Switch Camera"
      >
        🔁
      </div>
      <!-- <button >Save<ion-item *ngIf="spinner"> <ion-spinner name="crescent"></ion-spinner>
        </ion-item></button>
      <button class="clear" (click)="clearSignature()">Clear</button> -->
    </div>
    <div *ngIf="!data?.new && !camera">
      <button class="webcam" (click)="triggerSnapshot()">
        <img src="../assets/Group123.svg" />
      </button>

      <div
        class="actionBtn"
        (click)="showNextWebcam(true)"
        title="Switch Camera"
      >
        🔁
      </div>
      <!-- <button >Save<ion-item *ngIf="spinner"> <ion-spinner name="crescent"></ion-spinner>
        </ion-item></button>
      <button class="clear" (click)="clearSignature()">Clear</button> -->
    </div>
  </div>
</div>
