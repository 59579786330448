<div *ngIf="routePath == '/'+slug+'/main/admin-staff'" class="page-title">
    <h3>
        Staffs : {{userProfile?.name}} ({{userProfile?.role?.name}})
    </h3>
</div>
<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/main/admin-table'">
    Inventory : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/main/dashboard'">
    Dashboard : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/main/supplier'">
    Suppliers : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/main/service'">
    Services : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/main/branches'">
    Branches : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/manager-main/consultation'">
    <!-- <button class="back-buttons-header" style="background-color: transparent;" (click)="back('all')">
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.135 13.7588H5.875" stroke="#ffffff" stroke-width="2.04375" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.505 21.3885L5.875 13.7584L13.505 6.12842" stroke="#ffffff" stroke-width="2.04375" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> -->
    <!-- <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img> -->
    <!-- </button> -->
    Consultation : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/manager-main/customer-lookup'">
    Patient Lookup : {{userProfile?.name}} ({{userProfile?.role?.name}})
    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav  menu-p-back" *ngIf="routePath == '/'+slug+'/manager-main/roles-users'">
    Role & Users : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>



<div class="sticky-Body-Nav  menu-p" *ngIf="routePath == '/'+slug+'/main/admin-sales-transactions'">
    <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 10px;    filter: invert(1);margin-left: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button> Sales

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>



<div class="sticky-Body-Nav  menu-p" *ngIf="routePath == '/'+slug+'/main/admin-table'">
    <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button> Inventory : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>


<div class="sticky-Body-Nav heading-text-value  menu-p" *ngIf="routePath == '/'+slug+'/manager-main/manage-users'">
    <button style="background-color: transparent;" (click)="back('roles-users')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button> Manage Users : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>



<div class="sticky-Body-Nav  menu-p" *ngIf="routePath == '/'+slug+'/manager-main/follow-up'">
    <button style="background-color: transparent;" (click)="back('all')">
    <ion-img style="padding-right: 15px;" src="../../../assets/backIcon.svg"></ion-img>
  </button> Follow Up : {{userProfile?.name}} ({{userProfile?.role?.name}})

    <span class="openMenu" (click)="hideShow()">
    <svg class="menu-icon"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H20M4 18H20" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
  </span>
    <div [ngClass]="crud?.showSidebar ? ' opensidebar' : 'closesidebar'" *ngIf="crud?.showSidebar">
        <app-sidebar></app-sidebar>
    </div>
</div>