import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { FoodEditComponent } from 'src/app/components/food-edit/food-edit.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
export interface UserData {
  id: string;
  name: string;
  brand: string;
  dosageform: string;
  strength: string;
}

/** Constants used to fill up our data base. */
const DOSAGEFORM: string[] = [
  '15 lbs',
  '15 lbs',
  '15 lbs',
  '15 lbs',
  '15 lbs',
  '15 lbs',
  '15 lbs',
  '15 lbs',
];
const STRENGTH: string[] = [
  'Adult',
  'Adult',
  'Adult',
  'Adult',
  'Adult',
  'Adult',
  'Adult',
  'Adult',

];
const NAMES: string[] = [
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
  'Antibiotic X',
];
export interface PeriodicElement {
  Name: string;
  TAC: number;
  TDC: string;
  DND: number;
  AHT: number;
  Renewal: number;
}

@Component({
  selector: 'app-admin-food',
  templateUrl: './admin-food.component.html',
  styleUrls: ['./admin-food.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0',display:'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class AdminFoodComponent implements AfterViewInit {
  ngOnInit() {
  }


  displayedColumns: string[] = ['productName', 'brand', 'weight', 'lifeStage', 'nutritionalInformation', 'type', 'flavour', 'currentQuantity', 'reorderLevel', 'reQuantity', 'batchNo', 'purchasePrice', 'retailPrice', 'maxRetailDiscount', 'barCode', 'location', 'action'];
  dataFood : any=new MatTableDataSource([]);
  dataFoodWithPageSize: any = new MatTableDataSource([]);
  showChilds:any
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];


  totalCount: any = 0;//
  pageSize = 10;//

  pageSizeOptions: number[] = [5, 10, 25, 50, 100];//
  skip = 0;
  expandedElement: PeriodicElement | null | any;
  limit: any = 100;
  searchVariable: any;

  userProfile: any = localStorage.getItem('userProfile');

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  constructor(
    public dialog: MatDialog,
    private CrudService: CrudService,
    private toast: ToastComponent

  ) {
    this.userProfile = JSON.parse(this.userProfile);
    this.CrudService.branchId = localStorage.getItem('branchId')

    // Create 100 users

    // Assign the data to the data source for the table to render
    this.getall()
  }

  ngAfterViewInit() {
    this.getCustomerCount();
    // this.getall();
  }
  searchComplete() {
    //console.log(this.searchVariable)
    let filter = {

      "where": {
        "productName": { "$regex": this.searchVariable, "$options": "i" },
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      },

    }
    this.CrudService.get(environment.api.food, filter).then((res: any) => {
      this.dataFood = res;
      //console.log(this.dataFood, 'this.dataFood')
      setTimeout(() => {
        if (!res.length) {
          this.toast.presentToastError("Data is not found related to search : " + this.searchVariable)
        }
      }, 100);
    })

  }
  getCustomerCount() {
    // this.CrudService.count(environment.api.accessories).then((res: any) => {
    //   this.totalCount = res;
    // })
  }
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, id: any): void {
    const dialogref = this.dialog.open(FoodEditComponent, {
      height: '550px',
      width: '800px',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        title: 'food',
        id: id
      }

    });
    dialogref.afterClosed().subscribe((res: any) => {
      this.getall()


    })

  }
  openDialogadd(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogref = this.dialog.open(DialogboxComponent, {


      height: '550px',
      width: '800px',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        title: 'AddFood'
      }

    });
    dialogref.afterClosed().subscribe((res: any) => {
      this.getall()


    })

  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }
  getall() {
    //console.log('rohit')
    let filter = {
      where: {
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }

    }
    this.CrudService.get(environment.api.food, filter).then((res:any) => {
      // this.dataFood = new MatTableDataSource(res);
      this.dataFood.data = []

      res?.map((item1:any)=>{
        if(item1?.foodId == null || item1?.foodId == ''){
          //console.log(item1?.foodId,'foodid')
          this.dataFood.data.push(item1) ;

        }
      })
      this.dataFood.paginator = this.paginator;
      this.dataFoodWithPageSize.paginator = this.paginatorPageSize;
      this.dataFood.sort = this.sort;
      this.expandedElement=null
      //console.log(this.dataFood, 'this.dataSource');


    })
  }
  // getServerData(event: any) {
  //   let filter = {
  //     where: {
  //       "clientId": this.userProfile?.clientId?._id,
  //       "branchId": this.CrudService?.branchId
  //     },
  //     "limit": event.pageSize,
  //     "skip": event.pageIndex * 10,
  //   }
  //   this.CrudService.get(environment.api.food, filter).then((res: any) => {
  //     this.dataSource.data = res;
  //     this.skip += res.length;
  //   })

  // }

  deletebyid(id: any) {
    this.CrudService.deleteid(id, environment.api.food).then((res: any) => {
      this.toast.presentToast("Deletion Complete")
      this.getall();

    })

  }
  AddNew(id:any){
    //console.log(id,'id')

    const dialogref = this.dialog.open(DialogboxComponent, {
      height: '600px',
      width: '800px',
      data: {
        id:id,
        title: 'AddNewFood'
      }

    });
    dialogref.afterClosed().subscribe((res: any) => {
      this.getall()

    })

  }
  showChild(ids:any){
    this.showChilds=ids

  }


}

function createNewUser(id: number): UserData {
  const name =
    NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
    ' ' +
    NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
    '.';

  return {
    id: id.toString(),
    name: name,
    strength: STRENGTH[Math.round(Math.random() * (STRENGTH.length - 1))],
    brand: Math.round(Math.random() * 100).toString(),
    dosageform: DOSAGEFORM[Math.round(Math.random() * (DOSAGEFORM.length - 1))],
  };
}
