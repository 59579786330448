import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListService implements CanActivate {
  userprofile: any;
  userProfile: any;

  constructor(
    public route: Router,
    private navCtrl:NavController

  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("token")) {
      let url = state?.url?.split('/');
      //console.log(url[3], 'url');
      this.userProfile = localStorage.getItem('userProfile');
      this.userprofile = JSON.parse(this.userProfile);
      if (this.userprofile?.role?.name == "Manager") {
        if (url[3] == 'consultation' || url[3] == 'customer-lookup' || url[3] == 'branches' || url[3] == 'service' || url[3] == 'roles-users' || url[3] == 'admin-table' || url[3] == 'manage-users' || url[3] == 'dashboard' || url[3] == 'admin-sales-transactions' || url[3] == 'follow-up') {
          //console.log(url[3], 'url');

          return true;
        }
        else {
          return this.navCtrl.navigateForward([localStorage.getItem('clinic')+'/main/dashboard']);

        }
      }
      else if (this.userprofile?.role?.name == "Doctor") {
        //console.log(url[3], 'url');
        if (url[3] == 'consultation' || url[3] == 'customer-lookup' || url[3] == 'follow-up') {
          return true;
        }
        else {
          return this.navCtrl.navigateForward([localStorage.getItem('clinic')+'/manager-main/consultation']);
        }
      }
      else if (this.userprofile?.role?.name == "Nursing") {
        //console.log(url[3], 'url');
        if (url[3] == 'consultation' || url[3] == 'customer-lookup' || url[3] == 'follow-up') {
          return true;
        }
        else {
          return this.navCtrl.navigateForward([localStorage.getItem('clinic')+'/manager-main/consultation']);
        }
      }
      else if (this.userprofile?.role?.name == "Front Desk") {
        //console.log(url[3], 'url');
        if (url[3] == 'consultation' || url[3] == 'customer-lookup' || url[3] == 'follow-up' || url[3] == 'admin-sales-transactions') {
          return true;
        }
        else {
          return this.navCtrl.navigateForward([localStorage.getItem('clinic')+'/manager-main/consultation']);
        }
      }
      else {
        //console.log("rohit");
        return this.navCtrl.navigateForward([localStorage.getItem('clinic')+'/manager-main/consultation']);
      }
    }
    else {
      return this.navCtrl.navigateForward([localStorage.getItem('clinic')+'/manager-main/consultation']);
    }
  }
}
