import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

// @Component({
//   selector: 'app-dialog',
//   templateUrl: './dialog.component.html',
//   styleUrls: ['./dialog.component.scss'
// ]
// })

@Component({
  selector: 'dialog-data-dialog',
  templateUrl: 'dialog-data-dialog.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogDataDialog implements OnInit {
	dialogRef: any;

  constructor(
    public dialog: MatDialog, 
  ) { }
  
  public data :any;
  ngOnInit(): void {
  }
  openDialog(dialogTitle: string, dialogContent : string,width:string = '50%',minHeight:string="150",) {
    this.dialogRef = this.dialog.open(DialogDataDialog, {
     width: width,
     minHeight:minHeight,
     height:minHeight,
     disableClose: true,
     data: {title: dialogTitle, content : dialogContent}
   });
    return new Promise((resolve, reject) => {
       this.dialogRef.afterClosed().subscribe((result:any) => {
        console.log('jjjjj',result)
          resolve(result);
          
       });
     });
 }


 
}


// @Component({
//   selector: 'dialog-data-dialog',
//   templateUrl: 'dialog-data-dialog.html',
//   styleUrls: ['./dialog.component.scss']
// })
// export class DialogDataDialog {
//   constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
// }