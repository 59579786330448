import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { NavController } from '@ionic/angular';
@Component({
  selector: 'app-choose-pet',
  templateUrl: './choose-pet.component.html',
  styleUrls: ['./choose-pet.component.scss'],
})
export class ChoosePetComponent implements OnInit {
  // socket = io('http://localhost:4002/');
  selectpetval: boolean = false;
  petdata: any;
  petid: any;
  name: any;
  addappoint: any;
  deleteid: any;
  parentdata: any;
  dateselect: boolean = false;
  userProfile: any = localStorage.getItem('userProfile');
  minDate: string;
  constructor(
    private route: Router,
    private toast: ToastComponent,
    public dialogRef: MatDialogRef<ChoosePetComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private CrudService: CrudService,
    private datePipe: DatePipe,
    private navCtrl: NavController
  ) {
    this.userProfile = JSON.parse(this.userProfile);
    this.addappoint = {
      appoinmentTime: '',
    };
    const currentdate = new Date();
    this.minDate = formatDate(currentdate, 'yyyy-MM-dd', 'en');
    //console.log(this.data.id, 'pertch')
    // if(this.data.pet == 'PetCheck'){
    //   this.petCheckIn =

    // }
  }

  ngOnInit() {
    this.CrudService.getbyid(this.data.id, environment.api.customer).then(
      (res: any) => {
        this.petdata = res?.dog;
        this.petid = res?.dog[0]?._id;
        this.selectpetval = res?.dog[0]?._id;
        this.name = res?.dog[0]?.name;
        this.checget(res?.dog[0]?._id, 1);

        console.log(this.petdata, 'data');
      }
    );
  }

  selectpet(id: any, name: any, index: any): void {
    console.log(index,'index')
    this.selectpetval = id;
    this.petid = id;
    this.name = name;
    this.checget(this.petid, index);
  }

  selectpet1(id: any, name: any, index: any): void {
    this.selectpetval = id;
    this.petid = id;
    this.name = name;
    // this.checappoint(this.petid,index);
  }

  show(date: any) {
    this.dateselect = true;
    this.addappoint.appoinmentTime = date;
    //console.log('njj', this.addappoint.appoinmentTime)
  }

  continuepage(id: any, name: any): void {
    if (id != undefined) {
      let today = new Date();
      if (
        today.getDate() ===
          new Date(this.addappoint.appoinmentTime).getDate() &&
        today.getMonth() ===
          new Date(this.addappoint.appoinmentTime).getMonth() &&
        today.getFullYear() ===
          new Date(this.addappoint.appoinmentTime).getFullYear()
      ) {
        this.addappoint = {
          ownerId: this.data.id,
          dogs: id,
          status: 'Pending',
          check: 'False',
          branchId: this.CrudService?.branchId,
          clientId: this.userProfile?.clientId?._id,
          appoinmentTime: new Date(),
        };
        this.CrudService.add(
          this.addappoint,
          'appointment/addNewApppointmentcopy'
        ).then((res: any) => {
          if (res.message == 'User Already Exist') {
            this.toast.presentToast('An appointment has been booked already');
          } else {
            this.dialogRef.close();

            //console.log(localStorage.getItem('clinic'), 'local')
            this.toast.presentToast('' + name + ' is added to consultation');
            this.navCtrl.navigateForward([
              localStorage.getItem('clinic') + '/manager-main/consultation',
            ]);
          }
        });
      } else {
        // this.addappoint.appoinmentTime = this.datePipe.transform(this.addappoint.appoinmentTime, 'yyyy-MM-ddTHH:mm:ss.SSSZ');
        this.addappoint.appoinmentTime = new Date(
          this.addappoint.appoinmentTime
        );
        this.addappoint.appoinmentTime.setMinutes(
          this.addappoint.appoinmentTime.getMinutes() + 480
        );
        //console.log(this.addappoint.appoinmentTime)
        this.addappoint = {
          ownerId: this.data.id,
          dogs: id,
          status: 'Pending',
          check: 'False',
          branchId: this.CrudService?.branchId,
          clientId: this.userProfile?.clientId?._id,
          appoinmentTime: this.addappoint.appoinmentTime,
        };
        this.CrudService.add(
          this.addappoint,
          'appointment/addNewApppointmentcopy'
        ).then((res: any) => {
          if (res.message == 'User Already Exist') {
            this.toast.presentToast('An appointment has been booked already');
          } else {
            this.dialogRef.close();

            //console.log(localStorage.getItem('clinic'), 'local')
            this.toast.presentToast('' + name + ' is added to consultation');
            this.navCtrl.navigateForward([
              localStorage.getItem('clinic') + '/manager-main/consultation',
            ]);
          }
        });
      }
    } else {
      this.dialogRef.close();

      this.toast.presentToast('Please select pet');
    }
  }

  continuecheck(id: any, name: any) {
    if (id != undefined) {
      //console.log(id, 'idid')
      this.addappoint = {
        ownerId: this.data.id,
        dogs: id,
        appoinmentTime: new Date(),
        status: 'Pending',
        check: 'True',
        branchId: this.CrudService?.branchId,
        clientId: this.userProfile?.clientId?._id,
        // "vet":this.name.preferedDoctor,
      };
      this.CrudService.add(this.addappoint, 'appointment/checkIn').then(
        (res: any) => {
          if (res.message == 'User Already Exist') {
            this.toast.presentToast('An appointment has been booked already');
          } else {
            this.dialogRef.close();

            //console.log(localStorage.getItem('clinic'), 'local')
            this.toast.presentToast(' ' + name + ' is added to consultation');
            this.navCtrl.navigateForward([
              localStorage.getItem('clinic') + '/manager-main/consultation',
            ]);
          }
        }
      );
    } else {
      this.dialogRef.close();

      this.toast.presentToast('Please select pet');
    }
  }

  closedialog() {
    this.dialogRef.close();
  }

  closedate() {
    this.addappoint.appoinmentTime = null;
  }

  continueedit(id: any) {
    //console.log(this.data.id, 'name ')
    this.dialogRef.close({ data: id, data2: this.data.id });
  }

  openpet(id: any, name: any) {
    this.dialogRef.close({ data1: id, data: name });
  }

  //   checappoint(id:any,index:any)
  //   {
  //     var checpet :any;

  //     let filter = {
  //       "include": ["appoinmentTime","_id"],
  //       "where": {
  //         "clientId": this.userProfile?.clientId?._id,
  //         "branchId": this.CrudService?.branchId,
  //         "dogs" : id,
  //         "status": "Pending",
  //         "check" : "True"

  //       }
  //     }

  //     this.CrudService.get(environment.api.appoinment, filter).then((res1: any) => {

  //     checpet = res1[(res1?.length)-1]?.appoinmentTime;
  //     this.petdata[index].appoinmentTime=checpet;

  //     //console.log(res1, '9999999',this.petdata[index].appoinmentTime);

  // })

  // let filter1 = {
  //   "include": ["_id"],
  //   "where": {
  //     "clientId": this.userProfile?.clientId?._id,
  //     "branchId": this.CrudService?.branchId,
  //     "status": "Pending",
  //     "dogs" : id,
  //     "check":"True"

  //   }
  // }

  // this.CrudService.get(environment.api.appoinment, filter1).then((res: any) => {

  //    this.deleteid = res[(res?.length)-1]?._id;
  //    //console.log(res, '9999999');

  // })

  // }

  checget(id: any, index: any) {
    //console.log('ggg')
    var checpet: any;

    let filter = {
      include: ['appoinmentTime', '_id'],
      where: {
        clientId: this.userProfile?.clientId?._id,
        branchId: this.CrudService?.branchId,
        dogs: id,
        paymentStatus: { $ne: 'Completed' },
        // "check": "False"
      },
    };

    this.CrudService.get(environment.api.appoinment, filter).then(
      (res1: any) => {
        checpet = res1[res1?.length - 1]?.appoinmentTime;
        this.petdata[0].appoinmentTime = checpet;
        this.parentdata = res1[0];

        // console.log(res1, '9999999', this.petdata[index].appoinmentTime);
      }
    );

    let filter1 = {
      include: ['_id'],
      where: {
        clientId: this.userProfile?.clientId?._id,
        branchId: this.CrudService?.branchId,
        status: 'Pending',
        dogs: id,
        check: 'False',
      },
    };

    this.CrudService.get(environment.api.appoinment, filter1).then(
      (res: any) => {
        this.deleteid = res[res?.length - 1]?._id;
        //console.log(res, '9999999');
      }
    );
  }

  deleteappoint(id: any) {
    if (id) {
      this.CrudService.deleteid(id, environment.api.appoinment).then(
        (response: any) => {
          //console.log(response, 'delete'),
          this.closedialog();
        }
      );
    }
  }
  openAppoint(id: any) {
    this.closedialog();
    // this.navCtrl.navigateForward([localStorage.getItem('clinic') + '/manager-main/']);
    this.navCtrl.navigateForward([
      localStorage.getItem('clinic') +
        '/manager-main/consultation-pet-info/' +
        id +
        '/' +
        'appointment' +
        '/nosession',
    ]);
  }
}
