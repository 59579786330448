<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>Prescription History</h1>

    </div>
    <div style="padding-top: 6px;
  padding-right: 7px;">
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>
  </div>
</div>
<div class="popupbox-background" style="">
  <div class="side-bg-value">

    <div class="datasideloadmore" *ngFor="let data4 of viewDetailslog?.reverse()">

      <ion-row class="data-info-pet-tabs">

        <div class="initiateTreatment">
          <div class="add-bg-box">
            <div class="name-bg-box">

              <div class="left">
                <p class="name-text">
                  <span>{{data4?.treatDoctor?.name}}</span>
                </p>
                <!-- <div class="address-bg-box records">
                  <p class="clinic-add-text new-address">
                    {{data4?.clientId?.name}}, {{data4?.branchId?.branch}}
                  </p>

                </div> -->
              </div>

              <p class="today-text">
                {{data4?.appoinmentTime | date : "dd/MM/yyyy hh':'mm"}}
              </p>

            </div>


            <!-- <div>

              <p *ngIf="data4?.treatmentNotes != ''" class="treatment-text-value new-clinic-text">
                <span *ngFor="let data of data4?.treatmentNotes;let i = index;">
                  {{data}}

                </span>
              </p>
              <p *ngIf="data4?.treatmentNotes == ''" class="treatment-text-value new-clinic-text">
                No TreatMent Notes
              </p>
            </div> -->
            <div class="log-bg-box-value">
              <div class="vie-presboc">
                <button title="View Detail" (click)="logbtn(data4?._id)">
                  <ion-icon name="eye"></ion-icon>
                </button>

              </div>

              <div class="repeat-button-div">
                <!-- <button class="log-button">
                  <span *ngIf="viewDetailslog?.length == 0" class="logBt">
                    <ion-icon class="log-icon" name="document-text-outline"></ion-icon>
                  </span>

                  <span *ngIf="data4?.sessionData?.length == 0" 
                    class="logBt"><ion-icon class="log-icon" name="document-text-outline"></ion-icon></span>

                </button> -->

                <!-- <button class="start-btn dark-color-btn" (click)="repeatData(data4?._id)">
                  Repeat
                </button> -->
              </div>
              <!-- <div *ngIf="sessionAppointId?.indexOf(data4?._id) != -1 && data4?.sessionData?.length > 0">
                <mat-form-field class="example-full-width" style="margin-left: 0;" [floatLabel]="'always'">
                  <mat-label> View Detail Log</mat-label>
                  <mat-select placeholder="Select" (selectionChange)="logbtn($event.value)">
                    <span *ngFor="let detaillog of viewDetailslogSession;let i = index;">
                      <mat-option [value]="detaillog?._id" *ngIf="detaillog?.appointmentId == data4?._id">
                        {{detaillog?.appoinmentTime | date: "dd-MM-yyyy HH:mm"}}
                      </mat-option>
                      <mat-option [value]="detaillog?._id" *ngIf="detaillog?._id == data4?._id">
                        {{detaillog?.appoinmentTime | date: "dd-MM-yyyy HH:mm"}}
                      </mat-option>
                    </span>


                  </mat-select>
                </mat-form-field>
              </div> -->



              <!-- <div *ngIf="data4?._id != sessionData?.appointmentId">
                <button
                  [disabled]="customerId == 'appointment' && customer?.paymentStatus != 'Completed' && customer?.newConsult || customer?.moveToBilling || sessionData?.disable"
                  class="start-btn dark-color-btn"
                  [ngClass]="customerId == 'appointment' && customer?.paymentStatus != 'Completed' && customer?.newConsult || customer?.moveToBilling || sessionData?.disable ? 'disablebutton' : 'start-btn dark-color-btn'"
                  (click)="newSession(data4?._id)">
                  New Session
                </button>
              </div>
              <div *ngIf="sessionData?.newSessionConsult && data4?._id == sessionData?.appointmentId">
                <button
                  [disabled]="customerId == 'appointment' && customer?.paymentStatus != 'Completed' && customer?.newConsult || sessionData?.disable"
                  class="start-btn dark-color-btn" class="resume-session"
                  [ngClass]="customerId == 'appointment' && customer?.paymentStatus != 'Completed' && customer?.newConsult || sessionData?.disable ? 'disablebutton':'start-btn dark-color-btn'"
                  (click)="newSession(data4?._id)">
                  Resume Session
                </button>
              </div> -->
              <div>

              </div>


            </div>


          </div>
        </div>

      </ion-row>
    </div>

  </div>
</div>