import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
import { DOCUMENT } from '@angular/common';
import { CrudService } from 'src/app/services/crud/crud.service';
import { NavController } from '@ionic/angular';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
routePath:any;
slug:any=localStorage.getItem('clinic')
userProfile:any = localStorage.getItem('userProfile')
  constructor(public router :Router,
    public dialog: MatDialog,@Inject(DOCUMENT) private document: Document,
    public crud:CrudService,
    private navCtrl:NavController
    ) {
      this.userProfile = JSON.parse(this.userProfile);
      console.log("helllllo")

  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let data: any = localStorage.getItem('userProfile');
        if (
          this.userProfile == '' ||
          this.userProfile == null ||
          this.userProfile == undefined
        ) {
          this.userProfile = JSON.parse(data);
        }

        // This code will run every time a navigation to a new page is complete
        console.log('Navigated to:', this.userProfile);
        // Put your initialization logic here
      }
    });

    if(this.router.url === '/'+this.slug+'/main/admin-staff' || this.router.url === '/'+this.slug+'/main/admin-table' ||  this.router.url === '/'+this.slug+'/main/dashboard' || this.router.url === '/'+this.slug+'/main/supplier' || this.router.url === '/'+this.slug+'/main/service' || this.router.url === '/'+this.slug+'/main/branches' || this.router.url == '/'+this.slug+'/manager-main/consultation'
    || this.router.url == '/'+this.slug+'/manager-main/customer-lookup' || this.router.url === '/'+this.slug+'/manager-main/roles-users' || this.router.url === '/'+this.slug+'/main/admin-sales-transactions' || this.router.url === '/'+this.slug+'/main/admin-table' ||
    this.router.url == '/'+this.slug+'/manager-main/manage-users' || this.router.url == '/'+this.slug+'/manager-main/follow-up')
    {
      this.routePath = this.router.url;
      console.log("helllllo" , this.routePath,'routePath')
    }

  }

  back(data:any){
    if(data == 'all'){

      this.navCtrl.navigateForward([localStorage.getItem('clinic')+"/main/dashboard"])
    }
    if(data == 'roles-users'){

      this.navCtrl.navigateForward([localStorage.getItem('clinic')+"/manager-main/roles-users"])
    }

  }

  hideShow(){
    this.crud.showSidebar =  !this.crud?.showSidebar;
    this.document.body.classList.add('menuopen');
  }


}
