// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  
  firebase: {
    apiKey: "AIzaSyDghbp0khgqvvJJHHQsNjf1wirOXhPiGwQ",
    authDomain: "dogsandjoys-37499.firebaseapp.com",
    projectId: "dogsandjoys-37499",
    storageBucket: "dogsandjoys-37499.appspot.com",
    messagingSenderId: "576016011046",
    appId: "1:576016011046:web:f40f15f761acd2cfa0fbe7",
    measurementId: "G-68D47GCD5S"
  },
  api: {
service:"service",
treatment:"treatment",
    login: "login",
    sendotp : "sendotp ",
    inventory: 'inventory',
    medicine: 'medicine',
    businessUser: "businessUser",
    food: "food",
    accessories: "accessories",
    customer: "customer",
    appoinment:"appoinment",
    appoinmentimages:"appoinmentimages",
    getAppointmentWithAddress:"getAppointmentWithAddress",
    branch:"branch",
    client:"clients",
    role:"role",
    permission:"permission",
    dogspet:"dogspet"


  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
