<ion-app>
  <ion-router-outlet></ion-router-outlet>
</ion-app>

<div class="abc"></div>
<div class="loaderClassforloader" *ngIf="loader.loading">
  <div class="row">
    <div class="col-md-12">
      <div class="lottieWrapper">
        <ng-lottie [options]="lottieConfig" class="lottieClass" *ngIf="loadingFlag"
          (animationCreated)="animationCreated($event)"><img src="../assets/spinner/doctoricon.png " height="100px"></ng-lottie>
      </div>
      

    </div>
  </div>
</div>
