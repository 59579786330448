<div class="billing-main"
  *ngIf="data?.edit == 'add' && data?.title == 'Treatment' || data?.title == 'Diagnostic' || data?.title == 'Nursing' || data?.title == 'Support'">

  <div class="inventory-edit">
    <div class="center-modal-header">

      <div>
        <h1>Add {{data.title}}</h1>

      </div>
      <div style="padding-top: 6px;padding-right: 7px;">
        <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
      </div>
    </div>
  </div>
  <div class="services-list services-list-add">
    <div>
      <div class="bg-white-fa">

        <div class="box-flex">
          <div *ngIf="!othersvalue">
            <input class="basic-input-field modalSearch" matInput (keyup)="searchComplete()"
              [(ngModel)]="searchVariable" #input placeholder="Search...">
          </div>

        </div>


        <div class="mat-elevation-z8">
          <div class="table-container">
            <form (keydown.enter)="$event.preventDefault()" class="searchResults">
              <table *ngIf="!othersvalue" mat-table [dataSource]="dataSource" matSort>

                <!-- ID Column -->

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="noShow"> P duct Name </th>
                  <td mat-cell *matCellDef="let row"> {{row.serviceName}},
                    <span>Rs {{row?.price}}</span>
                    <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                  </td>
                </ng-container>

                <ng-container matColumnDef="action" stickyEnd>
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="noShow"> Action </th>
                  <td mat-cell *matCellDef="let row" class="actionbtn two-icons">
                    <ion-button (click)="save(row._id)">Add</ion-button>
                  </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" sticky></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell search-feedback minPad" colspan="4"><span *ngIf="!othersvalue">
                      No data matching the filter
                    </span></td>
                </tr>
              </table>

              <span class="oothers"><button (click)="others()">Others</button></span>
              <span class="otherValue" *ngIf="othersvalue"><input type="text" [(ngModel)]="addForm.price"
                  name="addForm.price" placeholder="Rs.">
                <button (click)="saveOthers()">ADD</button></span>

            </form>
          </div>

        </div>
      </div>

    </div>
    <!-- <div class="modalFooter">

      <div>
        <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>


      </div>
    </div> -->
  </div>
</div>

<div *ngIf="data?.edit == 'add' && data?.title == 'Medicine'" class="billing-main">
  <div class="inventory-edit">
    <div class="center-modal-header">

      <div>
        <h1>Add Medicine, Food & Accessories</h1>

      </div>
      <div style="padding-top: 6px;
  padding-right: 7px;">
        <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
      </div>
    </div>
  </div>
  <div class="services-list services-list-add">
    <div>
      <div class="bg-white-fa">

        <div class="box-flex">
          <div *ngIf="!othersvalue">
            <input class="basic-input-field modalSearch" matInput (keyup)="searchCompletemedicine()"
              [(ngModel)]="searchVariable" #input placeholder="Search...">
          </div>

        </div>


        <div class="mat-elevation-z8">
          <div class="table-container">
            <form (keydown.enter)="$event.preventDefault()" class="searchResults medication">
              <div *ngIf="!othersvalue">


                <table *ngIf="medicine != ''" mat-table [dataSource]="medicine" matSort>

                  <!-- ID Column -->

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row">Medicine - {{row.productName}},
                      <span>Rs {{row?.RetailPrice}}</span>
                      <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row;let i =index">
                      <input type="text" class="form-control" name="quantity{{i}}" [(ngModel)]="addForm0.quantity[i]"
                        placeholder="{{addForm0.quantity}}">
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row" class="actionbtn two-icons">
                      <ion-button (click)="savemedicine(row._id,'medicine')">Add</ion-button>
                    </td>
                  </ng-container>



                  <tr mat-header-row *matHeaderRowDef="displayedColumnsmfa; sticky: true" sticky></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsmfa;"></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell search-feedback minPad" colspan="4">No data matching the filter ""</td>
                  </tr>
                </table>
                <table *ngIf="food != ''" mat-table [dataSource]="food" matSort>

                  <!-- ID Column -->

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row">Food - {{row.productName}},
                      <span>Rs {{row?.retailPrice}}</span>
                      <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row;let i =index">
                      <input type="text" class="form-control" name="qty2{{i}}" [(ngModel)]="addForm1.quantity"
                        placeholder="{{addForm1.quantity}}" id="myInputID">
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row" class="actionbtn two-icons">
                      <ion-button (click)="savemedicine(row._id,'food')">Add</ion-button>
                    </td>
                  </ng-container>



                  <tr mat-header-row *matHeaderRowDef="displayedColumnsmfa; sticky: true" sticky></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsmfa;"></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell search-feedback minPad" colspan="4">No data matching the filter ""</td>
                  </tr>
                </table>
                <table *ngIf="accessories != ''" mat-table [dataSource]="accessories" matSort>

                  <!-- ID Column -->

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row">Accesories - {{row.productName}},
                      <span>Rs {{row?.retailPrice}}</span>
                      <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row;let i =index">
                      <input type="text" class="form-control" name="qty{{i}}" [(ngModel)]="addForm.quantity"
                        placeholder="{{addForm.quantity}}">
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                    <td mat-cell *matCellDef="let row" class="actionbtn two-icons">
                      <ion-button (click)="savemedicine(row._id,'accessories')">Add</ion-button>
                    </td>
                  </ng-container>



                  <tr mat-header-row *matHeaderRowDef="displayedColumnsmfa; sticky: true" sticky></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsmfa;"></tr>

                  <!-- Row shown when there is no matching data. -->
                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell search-feedback minPad" colspan="4">No data matching the filter ""</td>
                  </tr>
                </table>
              </div>

              <span class="oothers"><button (click)="others()">Others</button></span>
              <span class="otherValue" *ngIf="othersvalue"><input type="text" [(ngModel)]="addForm.price"
                  name="addForm.price" placeholder="Rs.">
                <button (click)="saveOthers()">ADD</button></span>
            </form>
          </div>

        </div>
      </div>

    </div>
    <!-- <div class="modalFooter">
      <p>
        All set to add this food? Just give it one final check
      </p>
      <div>
        <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>


      </div>
    </div> -->
  </div>
</div>

<div
  *ngIf="data?.edit == 'edit' && data?.title == 'Treatmentedit' || data?.title == 'Diagnosticedit' || data?.title == 'Nursingedit' || data?.title == 'Supportedit'"
  class="billing-main">
  <div class="services-list">
    <div class="bg-white-fa">
      <div class="mat-elevation-z8">
        <div class="table-container">
          <form (keydown.enter)="$event.preventDefault()">
            <table mat-table [dataSource]="serviceData" matSort>
              <ng-container matColumnDef="medication">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Medication Name </th>
                <div class="outer-box">
                  <div class="button-bg-box">
                    <td class="removeItem" mat-cell *matCellDef="let row">
                      <button class="close-btn-value" (click)="deleteService(row._id)"><img class="close-icon-value"
                          src="../assets/close-icon.svg"></button>
                      <p>
                        {{row.serviceName}}
                      </p>
                  </div>
                </div>


              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
                <td mat-cell *matCellDef="let row"> Rs {{(row?.price ? row?.price : 0).toFixed(1) | number:'1.0-0'}}
                  <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                </td>
              </ng-container>
              <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Discount In % </th>
                <td mat-cell *matCellDef="let row;let i =index">
                  <input type="text" class="form-control" name="discount{{i}}" [(ngModel)]="row.discount"
                    (input)="discountCal(row.discount,row.serviceId,row?._id)" maxlength="2"
                    placeholder="{{row.discount | number:'1.0-0'}}" id="myInputID">

                </td>
              </ng-container>
              <ng-container matColumnDef="gst">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> GST</th>
                <td mat-cell *matCellDef="let row">Rs {{(row?.GSTAmount ? row?.GSTAmount : 0).toFixed(1) | number:'1.0-0'}}

              </ng-container>
              <ng-container matColumnDef="afterdiscount" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Price After Discount </th>
                <td mat-cell *matCellDef="let row"> Rs {{(row?.netPrice ? row?.netPrice : 0).toFixed(1) | number:'1.0-0'}}
                  <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true" sticky></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>

              <!-- Row shown when there is no matching data. -->

            </table>
            <div class="total-amt-box">
              <h1 class="total-text-value" *ngIf="this.discount != 0">Total </h1>
              <svg width="436" height="2" viewBox="0 0 436 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="1" x2="436" y2="1" stroke="black" stroke-dasharray="1 9" />
              </svg>

              <h1 class="total-text-value">
                Rs {{(discount ? discount : 0).toFixed(1) | number:'1.0-0'}}
              </h1>
            </div>

            <h1 class="total-text-value" *ngIf="this.discount == 0" style="margin:15px">No data found</h1>
          </form>
        </div>

      </div>
    </div>

  </div>

</div>
<div *ngIf="data?.edit == 'edit' && data.title == 'Medicineedit'" class="billing-main">
  <div class="services-list">
    <div class="bg-white-fa">
      <div class="mat-elevation-z8">
        <div class="table-container">
          <form (keydown.enter)="$event.preventDefault()">
            <table mat-table [dataSource]="serviceData" matSort>


              <ng-container matColumnDef="medication">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Medication Name </th>
                <div class="outer-box">
                  <div class="button-bg-box">
                <td class="removeItem" mat-cell *matCellDef="let row"><button (click)="delete(row._id,
                  row?.serviceId,
                  row?.quantity,
                  row?.serviceType)"><img
                      src="../assets/close-icon.svg"></button>
                  <p>{{row.serviceName}}</p>
                  <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                </td>
                </div>
                </div>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
                <td mat-cell *matCellDef="let row"> Rs {{(row?.originalPrice ? row?.originalPrice : 0).toFixed(1) | number:'1.0-0'}}
                  <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                </td>
              </ng-container>
              <ng-container matColumnDef="qty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
                <td mat-cell *matCellDef="let row;let i=index;">
                  <input type="text" class="form-control" name="quantity{{i}}" [(ngModel)]="row.quantity"
                    (keyup)="quantityCalmfa(row.quantity,row.serviceId,row?._id,row.serviceType)" maxlength="2"
                    placeholder="{{row.quantity | number:'1.0-0'}}">
                </td>
              </ng-container>
              <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Discount In % </th>
                <td mat-cell *matCellDef="let row;let i =index">
                  <input type="text" class="form-control" name="discount{{i}}" [(ngModel)]="row.discount"
                    (keyup)="discountCalmfa(row.discount,row.serviceId,row?._id,row.serviceType)" maxlength="2"
                    placeholder="{{row.discount | number:'1.0-0'}}" id="myInputID">

                </td>
              </ng-container>

              <ng-container matColumnDef="gst">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> GST </th>
                <td mat-cell *matCellDef="let row">Rs {{(row?.GSTAmount ? row?.GSTAmount : 0).toFixed(1) | number:'1.0-0'}}

              </ng-container>
              <ng-container matColumnDef="afterdiscount" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Net Payable </th>
                <td mat-cell *matCellDef="let row"> Rs {{(row?.netPrice ? row?.netPrice : 0).toFixed(1) | number:'1.0-0'}}
                  <!-- <input type="hidden" ([ngModel])="addForm.diagnostic.serviceName" value="{{row.serviceName}}"> -->

                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns1mfa; sticky: true" sticky></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1mfa;"></tr>

              <!-- Row shown when there is no matching data. -->

            </table>
            <!-- final price in breakdown -->
            <div class="breakdownPrice" *ngIf="this.discount != 0">
              <div>
                <h1>Total Rs </h1>
                <div class="dot"></div>
              </div>
              <h1>{{(discount ? discount : 0).toFixed(1) | number:'1.0-0'}}</h1>
            </div>
            <h1 *ngIf="this.discount == 0" style="margin:15px">No data found</h1>
          </form>
        </div>

      </div>
    </div>

  </div>

</div>
