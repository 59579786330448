<div class="Treatment-main-box parent">
    <div class="detSke" *ngIf="skeleton">
        <div>
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div class="indexPagi">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
        <div class="blocks">
            <div class="left">
                <div class="vitalSke">
                    <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
            </div>
            <div class="right">
                <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div *ngIf="!skeleton">
        <div class="handle">
            <div class="controls">
                <p class="title">
                    Consultation on
                    <span>{{
            treatmentData?.appoinmentTime | date : "dd-MM-yyyy HH:mm"
          }}</span>
                </p>
            </div>
            <div class="cancel-bg-box">
                <!-- <ion-icon (click)="closedialog()" class="icon" name="close-outline"></ion-icon> -->
                <button (click)="closedialog()">
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.312 10.0283L17.718 2.27265C18.0931 1.87985 18.0931 1.29082 17.718 0.898359C17.3429 0.50556 16.7805 0.50556 16.4057 0.898359L8.99968 8.65404L1.59365 0.898359C1.21856 0.50556 0.656087 0.50556 0.281318 0.898359C-0.0937726 1.29116 -0.0937726 1.88019 0.281318 2.27265L7.68734 10.0283L0.281318 17.784C-0.0937726 18.1768 -0.0937726 18.7658 0.281318 19.1583C0.468702 19.3545 0.74994 19.4528 0.937646 19.4528C1.12503 19.4528 1.40627 19.3545 1.59397 19.1583L9 11.4026L16.406 19.1583C16.5934 19.3545 16.8746 19.4528 17.0624 19.4528C17.2497 19.4528 17.531 19.3545 17.7187 19.1583C18.0938 18.7655 18.0938 18.1765 17.7187 17.784L10.312 10.0283Z"
              fill="black"
            />
          </svg>
        </button>
            </div>
        </div>

        <div class="childPadding">
            <div _ngcontent-xdx-c337="" class="TreatmentLog child" [ngClass]="{ withoutPagination: viewDetailslogSession?.length === 0 }">
                <div _ngcontent-xdx-c337="" class="log">
                    <div _ngcontent-xdx-c337="" class="vital-logs-data" *ngIf="
              treatmentData?.heartRate != '' ||
              treatmentData?.temperature != '' ||
              treatmentData?.weight != ''
            ">
                        <p _ngcontent-xdx-c337="" class="previous-log-table-vitals">
                            <span _ngcontent-xdx-c337="" class="previous-log-table-vitals-log">Vitals log </span
              ><span
                _ngcontent-xdx-c337=""
                class="previous-log-table-organization"
              >
                Only accessible to you and your organization
              </span>
                        </p>
                        <div _ngcontent-xdx-c337="" class="vitallogs">
                            <div _ngcontent-xdx-c337="" class="icon-box">
                                <!-- <div _ngcontent-xdx-c337=""><svg _ngcontent-xdx-c337="" xmlns="http://www.w3.org/2000/svg" width="22"
                    height="15" viewBox="0 0 22 15" fill="none">
                    <path _ngcontent-xdx-c337=""
                      d="M21.8059 5.49543C20.0017 3.24764 17.6886 1.61719 15.1241 0.785746C12.5594 -0.0457695 9.84334 -0.0457695 7.27875 0.785746C4.71409 1.61726 2.40099 3.24764 0.596888 5.49543C0.418255 5.7176 0.410483 6.05678 0.57853 6.28947L4.79611 12.1267C4.88155 12.2618 5.01193 12.3502 5.15621 12.3713C5.30048 12.3923 5.44617 12.3443 5.55844 12.2381C5.6707 12.1321 5.73966 11.9776 5.74908 11.8113C5.75849 11.6449 5.70742 11.4815 5.6081 11.3596L1.69882 5.92411C4.08182 3.16507 7.28899 1.54304 10.6744 1.3856V2.8259C10.6744 3.15941 10.9105 3.42984 11.2016 3.42984C11.4928 3.42984 11.7288 3.15941 11.7288 2.8259V1.3856C15.118 1.54333 18.3288 3.16859 20.7123 5.93329L16.7767 11.3961C16.683 11.5188 16.6369 11.6795 16.6482 11.842C16.6597 12.0043 16.728 12.1545 16.8376 12.2583C16.9471 12.3624 17.0888 12.4112 17.23 12.3937C17.3714 12.3764 17.5009 12.2942 17.5887 12.1661L21.8246 6.28946C21.9927 6.05677 21.9849 5.71759 21.8063 5.49542L21.8059 5.49543Z"
                      fill="black"></path>
                    <path _ngcontent-xdx-c337=""
                      d="M13.4711 11.1959L11.6786 6.79622C11.5917 6.5843 11.4058 6.44922 11.2015 6.44922C10.9972 6.44922 10.8113 6.5843 10.7244 6.79622L8.93197 11.1959C7.88818 11.6151 6.94838 12.3177 6.19313 13.2433C5.99378 13.4868 6.00437 13.8694 6.2169 14.098C6.42942 14.3264 6.7634 14.3143 6.96275 14.0708C8.06306 12.7278 9.59922 11.9662 11.2067 11.9662C12.8145 11.9662 14.3506 12.7278 15.4507 14.0708C15.6564 14.2687 15.9588 14.2579 16.1532 14.046C16.3479 13.8338 16.3721 13.4884 16.2099 13.2433C15.4547 12.3178 14.5149 11.6151 13.4711 11.1959L13.4711 11.1959ZM10.2419 10.8335L11.2015 8.48133L12.1611 10.8338V10.8335C11.5245 10.7329 10.8786 10.7329 10.2419 10.8335V10.8335Z"
                      fill="black"></path>
                  </svg></div> -->
                            </div>
                            <div class="weight-info">
                                <p *ngIf="treatmentData?.weight == ''">
                                    ...logged entries will appear here
                                </p>
                                <div class="weight-info-main" *ngIf="treatmentData?.weight != ''">
                                    <div class="weight-info-data" *ngFor="let data1 of treatmentData?.weight; let i = index">
                                        <h6 *ngIf="i < 3">
                                            {{ data1.time | date : "dd-MM-yyyy HH:mm" }}
                                        </h6>
                                        <h4 *ngIf="i < 3">{{ data1?.value }}Kg</h4>
                                    </div>
                                    <!-- <div class="weight-info-data">
                    <h6>2:35 PM, 1/2/23</h6>
                    <h4>27kg</h4>
                  </div>
                  <div class="weight-info-data">
                    <h6>2:35 PM, 1/2/23</h6>
                    <h4>27kg</h4>
                  </div> -->
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-xdx-c337="" class="vitallogs">
                            <div _ngcontent-xdx-c337="" class="icon-box">
                                <div _ngcontent-xdx-c337="">
                                    <svg _ngcontent-xdx-c337="" xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 13 24" fill="none">
                    <path
                      _ngcontent-xdx-c337=""
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.66359 2.26779C6.05212 2.26779 5.4656 2.51064 5.03314 2.94308C4.60085 3.37554 4.35786 3.9619 4.35786 4.57353V14.0844L4.32859 14.3932C4.32087 14.4744 4.27696 14.5478 4.2091 14.5928L4.06226 14.6903C3.36845 15.2319 2.86106 15.9767 2.61082 16.8207C2.36058 17.6645 2.38004 18.5655 2.66647 19.3978C2.9529 20.23 3.49199 20.9523 4.20861 21.4634C4.92507 21.9746 5.7834 22.2493 6.66359 22.2493C7.54379 22.2493 8.40196 21.9747 9.11858 21.4634C9.83504 20.9523 10.3741 20.23 10.6606 19.3978C10.947 18.5655 10.9664 17.6645 10.7162 16.8207C10.4661 15.9767 9.95874 15.2319 9.26492 14.6903C9.07837 14.5447 8.96933 14.3212 8.96933 14.0844V4.57353C8.96933 3.96193 8.72633 3.37554 8.29388 2.94308C7.86158 2.51062 7.27506 2.26779 6.66359 2.26779L6.66359 2.26779ZM2.8207 13.7257V4.57337C2.8207 3.55406 3.22565 2.57674 3.94631 1.85608C4.66697 1.13526 5.64445 0.730469 6.66359 0.730469C7.68274 0.730469 8.66022 1.13526 9.38088 1.85608C10.1015 2.57674 10.5063 3.55406 10.5063 4.57337V13.7257C11.3013 14.4367 11.8854 15.3563 12.19 16.3835C12.5313 17.5343 12.5047 18.7629 12.1141 19.8978C11.7235 21.0327 10.9883 22.0174 10.0113 22.7146C9.03419 23.4116 7.86386 23.7863 6.66364 23.7863C5.46343 23.7863 4.29294 23.4116 3.31595 22.7146C2.3388 22.0174 1.60365 21.0327 1.21318 19.8978C0.82254 18.7629 0.795997 17.5343 1.13727 16.3835C1.44187 15.3563 2.02599 14.4367 2.82079 13.7257H2.8207ZM6.66359 16.8704C6.02689 16.8704 5.51064 17.3865 5.51064 18.0232C5.51064 18.6601 6.02689 19.1762 6.66359 19.1762C7.3003 19.1762 7.81638 18.6601 7.81638 18.0232C7.81638 17.3865 7.3003 16.8704 6.66359 16.8704ZM7.43217 15.4447V8.41643C7.43217 7.992 7.08801 7.64785 6.66359 7.64785C6.23904 7.64785 5.89501 7.99202 5.89501 8.41643V15.4447C4.78389 15.7754 3.97365 16.8048 3.97365 18.0232C3.97365 19.5089 5.1779 20.7133 6.66359 20.7133C8.14913 20.7133 9.35354 19.5089 9.35354 18.0232C9.35354 16.8048 8.5433 15.7754 7.43217 15.4447Z"
                      fill="black"
                    ></path>
                  </svg>
                                </div>
                            </div>

                            <div class="weight-info">
                                <p *ngIf="treatmentData?.temperature == ''">
                                    ...logged entries will appear here
                                </p>

                                <div class="weight-info-main" *ngIf="treatmentData?.temperature != ''">
                                    <div class="weight-info-data" *ngFor="
                      let data2 of treatmentData?.temperature;
                      let i = index
                    ">
                                        <h6 *ngIf="i < 3">
                                            {{ data2.time | date : "dd-MM-yyyy HH:mm" }}
                                        </h6>
                                        <h4 *ngIf="i < 3">
                                            {{ data2.value }}<span>&#176;</span> C
                                        </h4>
                                    </div>
                                    <!-- <div class="weight-info-data">
                    <h6>2:35 PM, 1/2/23</h6>
                    <h4>27kg</h4>
                  </div>
                  <div class="weight-info-data">
                    <h6>2:35 PM, 1/2/23</h6>
                    <h4>27kg</h4>
                  </div> -->
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-xdx-c337="" class="vitallogs">
                            <div _ngcontent-xdx-c337="" class="icon-box">
                                <div _ngcontent-xdx-c337="">
                                    <svg _ngcontent-xdx-c337="" xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                    <path
                      _ngcontent-xdx-c337=""
                      d="M6.60976 9.91362L4.61767 4.38314L3.94746 6.20889C3.88204 6.38486 3.7138 6.50273 3.5255 6.50273H0.86925C0.621179 6.50273 0.419922 6.30085 0.419922 6.0534C0.419922 5.80554 0.621179 5.60407 0.86925 5.60407H3.21182L4.19995 2.91331C4.26494 2.7365 4.42712 2.61006 4.62337 2.61968C4.81251 2.6203 4.98053 2.73839 5.04469 2.91624L7.00168 8.3488L9.37496 0.714639C9.43222 0.531561 9.59796 0.40512 9.79002 0.398646C9.79482 0.398438 9.79921 0.398438 9.80465 0.398438C9.99002 0.398438 10.157 0.512964 10.2235 0.687262L12.1096 5.60504H14.4574C14.7055 5.60504 14.9068 5.80629 14.9068 6.05436C14.9068 6.30243 14.7055 6.50369 14.4574 6.50369H11.8007C11.6147 6.50369 11.4478 6.38854 11.3813 6.21508L9.84939 2.21834L7.46273 9.89477C7.40589 10.0793 7.23681 10.2064 7.04392 10.2112H7.03409C6.84433 10.2106 6.67526 10.0914 6.60984 9.91337L6.60976 9.91362Z"
                      fill="black"
                    ></path>
                  </svg>
                                </div>
                            </div>

                            <div class="weight-info">
                                <p *ngIf="treatmentData?.heartRate == ''">
                                    ...logged entries will appear here
                                </p>

                                <div class="weight-info-main" *ngIf="treatmentData?.heartRate != ''">
                                    <div class="weight-info-data" *ngFor="
                      let data3 of treatmentData?.heartRate;
                      let i = index
                    ">
                                        <h6 *ngIf="i < 3">
                                            {{ data3?.time | date : "dd-MM-yyyy HH:mm" }}
                                        </h6>
                                        <h4 *ngIf="i < 3">{{ data3?.value }}/bpm</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div _ngcontent-xdx-c337="" class="Symptoms-bg-box add-box" *ngIf="
              treatmentData?.treatmentNotes != '' ||
              treatmentData?.prescriptionImage != ''
            ">
                        <div _ngcontent-xdx-c337="" class="sym-inner-box">
                            <p _ngcontent-xdx-c337="" class="sym-text-value">
                                Treatment Notes
                            </p>
                            <div _ngcontent-xdx-c337="" class="dot-box"></div>
                            <p _ngcontent-xdx-c337="" class="org-text-value">
                                Accessible for other doctors and respective patient
                            </p>
                        </div>

                        <div _ngcontent-xdx-c337="" ng-reflect-ng-class="" class=""></div>
                        <div class="chips ">
                            <div *ngFor="
                  let data of treatmentData?.treatmentNotes;
                  let i = index
                ">
                                <h5>{{ data }}<button class="close-btn-del"></button></h5>
                            </div>
                        </div>
                        <div _ngcontent-xdx-c337="" ng-reflect-ng-class="" class=""></div>
                        <div class="chips chips-content">
                            <div class="chipsboxes" *ngFor="
                  let data of treatmentData?.prescriptionImage;
                  let i = index
                ">
                                <img [src]="data" />
                            </div>
                        </div>
                    </div>

                    <ion-row>
                        <!-- Symptoms only accessible -->
                        <ion-col>
                            <div _ngcontent-xdx-c337="" class="Symptoms-bg-box add-box divide-box" *ngIf="treatmentData?.symptoms != ''">
                                <div _ngcontent-xdx-c337="" class="sym-inner-box">
                                    <p _ngcontent-xdx-c337="" class="sym-text-value">Symptoms</p>
                                    <div _ngcontent-xdx-c337="" class="dot-box"></div>
                                    <p _ngcontent-xdx-c337="" class="org-text-value">
                                        Only accessible to you and your organization
                                    </p>
                                </div>

                                <div class="chips">
                                    <div *ngFor="let data of treatmentData?.symptoms; let i = index">
                                        <h5>{{ data }}<button class="close-btn-del"></button></h5>
                                    </div>
                                </div>
                            </div>
                        </ion-col>

                        <!-- Clinical examination div -->
                        <ion-col>
                            <div _ngcontent-xdx-c337="" class="Symptoms-bg-box add-box divide-box" *ngIf="treatmentData?.clinicalExamination != ''">
                                <div _ngcontent-xdx-c337="" class="sym-inner-box">
                                    <p _ngcontent-xdx-c337="" class="sym-text-value">
                                        Clinical examination
                                    </p>
                                    <div _ngcontent-xdx-c337="" class="dot-box"></div>
                                    <p _ngcontent-xdx-c337="" class="org-text-value">
                                        Only accessible to you and your organization
                                    </p>
                                </div>

                                <div class="chips">
                                    <div *ngFor="
                      let data of treatmentData?.clinicalExamination;
                      let i = index
                    ">
                                        <h5>{{ data }}<button class="close-btn-del"></button></h5>
                                    </div>
                                </div>
                            </div>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <!-- tentative diagnosis -->
                        <ion-col *ngIf="treatmentData?.tentativeDiagnosis != ''">
                            <div _ngcontent-xdx-c337="" class="Symptoms-bg-box add-box divide-box" *ngIf="treatmentData?.tentativeDiagnosis != ''">
                                <div _ngcontent-xdx-c337="" class="sym-inner-box">
                                    <p _ngcontent-xdx-c337="" class="sym-text-value">
                                        Tentative diagnosis
                                    </p>
                                    <div _ngcontent-xdx-c337="" class="dot-box"></div>
                                    <p _ngcontent-xdx-c337="" class="org-text-value">
                                        Only accessible to you and your organization
                                    </p>
                                </div>

                                <div class="chips">
                                    <div *ngFor="
                      let data of treatmentData?.tentativeDiagnosis;
                      let i = index
                    ">
                                        <h5>{{ data }}<button class="close-btn-del"></button></h5>
                                    </div>
                                </div>
                            </div>
                        </ion-col>

                        <ion-col>
                            <!-- <div _ngcontent-xdx-c337="" class="addedMeds" *ngIf="drugData.length > 0">
                <h1>Drugs</h1>
                <div class="medsCard">
                  <div *ngFor="let drugs of drugData">
                    <div>
                      <p>{{drugs?.name}}</p>
                      <p>{{drugs?.strengthP}}P ,{{drugs?.strengthML}}ML , {{drugs?.repeat}} for {{drugs?.duration}} days
                        in
                        {{drugs?.timeoftheday}}
                        ,
                        {{drugs?.tobeTaken}} - </p>
                      <p>
                        {{drugs?.bottle}}
                      </p>

                    </div>
                  </div>
                </div>
                <h1>Bottle Wise Drug</h1>
                <div>

                  <div *ngFor="let bottle of bottles">
                    <div>

                      <p>{{bottle.bottle}}</p>
                    </div>
                    <div *ngFor="let drugs of drugData">
                      <div *ngIf="drugs?.bottle == bottle?.bottle">
                        <p>{{drugs?.name}}</p>
                        <p>{{drugs?.strengthP}}P ,{{drugs?.strengthML}}ML , {{drugs?.repeat}} for {{drugs?.duration}} days
                          in
                          {{drugs?.timeoftheday}}
                          ,
                          {{drugs?.tobeTaken}} - </p>
                        <p>
                          {{drugs?.bottle}}
                        </p>

                      </div>
                    </div>

                  </div>
                </div>
              </div> -->
                            <div _ngcontent-xdx-c337="" class="addedMeds" *ngIf="
                  drugData.length > 0 &&
                  treatmentData?.clientId?.type === 'Homeopathy'
                ">
                                <h1>Drugs - Total Bottle ({{ bottles?.length }})</h1>
                                <ng-container *ngFor="let bottle of bottles">
                                    <div style="width: 100%; margin-bottom: 7px">
                                        <div>
                                            <p>{{ bottle.bottle }}</p>
                                        </div>
                                        <div class="medsCard">
                                            <ng-container *ngFor="let drugs of drugData">
                                                <ng-container *ngIf="drugs?.bottle == bottle?.bottle">
                                                    <div>
                                                        <div>
                                                            <p>{{ drugs?.name }}</p>
                                                            <p>
                                                                ({{ drugs?.strengthML }}
                                                                <ng-container *ngIf="drugs?.strengthML != 'Q'">p</ng-container>) , {{ drugs?.repeat }} for {{ drugs?.duration }} days in {{ drugs?.timeoftheday }} , {{ drugs?.tobeTaken }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div _ngcontent-xdx-c337="" class="addedMeds addedMeds-drugs" *ngIf="
                  drugData.length > 0 &&
                  treatmentData?.clientId?.type === 'Allopathy'
                ">
                                <h1>Drugs</h1>
                                <ng-container *ngFor="let drugs of drugData">
                                    <div style="margin-bottom: 7px">
                                        
                                        <div class="medsCard">
                                            <div>
                                                <div>
                                                    <p>{{ drugs?.name }}</p>
                                                    <p>
                                                        ({{ drugs?.strengthML }}
                                                        <ng-container *ngIf="drugs?.strengthML != 'Q'">p</ng-container>) , {{ drugs?.repeat }} for {{ drugs?.duration }} days in {{ drugs?.timeoftheday }} , {{ drugs?.tobeTaken }}, Qty : {{ drugs?.quantity }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ion-col>
                        <!-- <div _ngcontent-xdx-c337="" ng-reflect-ng-class="" class=""></div>
            <div class="chips">
              <div *ngFor="let data of treatmentData?.prescriptionImage;let i = index;">

                <img [src]="data">



              </div>
            </div> -->
                    </ion-row>

                    <!-- my code -->
                    <!-- <div>
           <table style="width: 100%; border-radius: 10px; overflow: hidden; margin-top: 1rem;">
            <tbody style="background-color: #ffffff; margin-top: 1rem;">
              <tr>
                <td style="display: flex;
                align-items: center;
                background: #f2f1ed;
                padding: 10px 20px;
                border-radius: 10px 10px 0 0;
                border-top-right-radius: 10px; gap: 10px;">


                    <p style="font-family: var(--font-family-pop);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16.3316px;
                    line-height: 24px;
                    margin: 0;
                    color: #000000;">Treatment Notes</p>


                    <p style="    font-family: var(--font-family-pop);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13.2961px;
                    line-height: 20px;
                    margin: 0;
                    color: #3C3939;">Accessible for other doctors and respective parent</p>
                </td>
              </tr>
              <tr>
                <td style="display: flex; gap: 20px; margin: 0px 20px; padding-bottom: 10px;">

                    <div *ngFor="let data of treatmentData?.treatmentNotes;let i = index;">
                      <h5 style="    border: 1px solid #2D7578; background: white; color: #2D7578; border-radius: 15px; display: flex; align-items: center; padding: 10px 25px 10px 16px; font-size: 0.9em; font-weight: 500;">{{data}}<button class="close-btn-del"></button>
                      </h5>
                    </div>

                </td>
              </tr>
              <tr>
                <td style="display: flex; gap: 20px; margin: 0px 20px; padding-bottom: 10px;">
                    <div *ngFor="let data of treatmentData?.prescriptionImage;let i = index;" style="border: 1px solid #bdbdbd;">
                      <img [src]="data">
                    </div>
                </td>
              </tr>
            </tbody>
            </table>

            <table>

                <tr>
                  <td style="padding-right: 10px;">
                    <table style="background-color: white; border-radius: 10px; overflow: hidden; margin-top: 1rem;">
                      <tr style="background: #f2f1ed; padding: 10px 20px;">
                        <td style="padding: 0.8rem;">
                          <p  style="font-family: var(--font-family-pop); font-style: normal; font-weight: 500; font-size: 16.3316px; line-height: 24px; margin: 0; color: #000000;">Symptoms </p>
                        </td>
                        <td style="padding: 0.8rem;">
                          <p  style="font-family: var(--font-family-pop); font-style: normal; font-weight: 400; font-size: 13.2961px; line-height: 20px; margin: 0; color: #3C3939;"> Only accessible to you and your organization </p>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; padding-left: 1rem;">
                           <div *ngFor="let data of treatmentData?.symptoms;let i = index;">

                        <h5 style="border: 1px solid #2D7578;
                        background: white;
                        color: #2D7578;
                        border-radius: 15px;
                        display: flex;
                        align-items: center;
                        padding: 10px 25px 10px 16px;
                        font-family: var(--font-family-pop);
                        font-size: 0.9em;
                        font-weight: 500;">{{data}}<button style="background: none !important; bottom: -2px; position: relative;right: -11px;"></button></h5>

              </div>
                        </td>
                      </tr>
                    </table>

                  </td>

                  <td>
                    <table style="background-color: white; border-radius: 10px; overflow: hidden; margin-top: 1rem;">
                      <tr style="background: #f2f1ed; padding: 10px 20px;">
                        <td style="padding: 0.8rem;">
                          <p  style="font-family: var(--font-family-pop); font-style: normal; font-weight: 500; font-size: 16.3316px; line-height: 18px; margin: 0; color: #000000;">Clinical examination </p>
                        </td>
                        <td style="padding: 0.8rem;">
                          <p  style="font-family: var(--font-family-pop); font-style: normal; font-weight: 400; font-size: 13.2961px; line-height: 20px; margin: 0; color: #3C3939;"> Only accessible to you and your organization </p>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; padding-left: 1rem;">
                          <div *ngFor="let data of treatmentData?.clinicalExamination;let i = index;">

                            <h5 style="    border: 1px solid #2D7578;
                            background: white;
                            color: #2D7578;
                            border-radius: 15px;
                            display: flex;
                            align-items: center;
                            padding: 10px 25px 10px 16px;
                            font-family: var(--font-family-pop);
                            font-size: 0.9em;
                            font-weight: 500;">{{data}}<button style="background: none !important; bottom: -2px; position: relative; right: -11px;"></button></h5>
                          </div>
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>

                <tr>
                  <td style="padding-top: 8px;">
                    <table style="background-color: white; border-radius: 10px;overflow: hidden; width: 97%;">
                      <tr style="background: #f2f1ed; padding: 10px 20px;">
                        <td style="padding: 0.8rem;">
                          <p  style="font-family: var(--font-family-pop); font-style: normal; font-weight: 500; font-size: 16.3316px; line-height: 18px; margin: 0; color: #000000;">Tentative diagnosis</p>
                        </td>
                        <td style="padding: 0.8rem;">
                          <p  style="font-family: var(--font-family-pop); font-style: normal; font-weight: 400; font-size: 13.2961px; line-height: 20px; margin: 0; color: #3C3939;"> Only accessible to you and your organization </p>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; padding-left: 1rem;">
                          <div *ngFor="let data of treatmentData?.tentativeDiagnosis;let i = index;">

                            <h5 style="border: 1px solid #2D7578;
                            background: white;
                            color: #2D7578;
                            border-radius: 15px;
                            display: flex;
                            align-items: center;
                            padding: 10px 25px 10px 16px;
                            font-family: var(--font-family-pop);
                            font-size: 0.9em;
                            font-weight: 500;">{{data}}<button style="background: none !important; bottom: -2px; position: relative; right: -11px;"></button></h5>
                          </div>
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>

            </table>
          </div> -->

                    <!-- <div  class="Symptoms-bg-box add-box" *ngIf="treatmentData?.treatmentNotes != ''">
            <div _ngcontent-xdx-c337="" class="sym-inner-box">
              <p _ngcontent-xdx-c337="" class="sym-text-value">Treatment Notes </p>
              <div _ngcontent-xdx-c337="" class="dot-box"></div>
              <p _ngcontent-xdx-c337="" class="org-text-value"> Accessible for other doctors and respective parent </p>
            </div>

            <div _ngcontent-xdx-c337="" ng-reflect-ng-class="" class=""></div>
            <div class="chips">
              <div *ngFor="let data of treatmentData?.treatmentNotes;let i = index;">

                <h5>{{data}}<button class="close-btn-del"></button>
                </h5>


              </div>
            </div>

          </div> -->

                    <div class="extraSpacing"></div>
                    <!-- </div> -->
                    <div _ngcontent-xdx-c337="" class="report">
                        <!-- <div _ngcontent-xdx-c337="" class="reportcard">
            <div _ngcontent-xdx-c337="" class="files">
              <div _ngcontent-xdx-c337="" class="content-rep">
                <h4 _ngcontent-xdx-c337="">Lab Reports</h4>



              </div>
            </div>
            <div _ngcontent-xdx-c337="" class="Note">
              <div *ngIf="treatmentData?.reports" class="placeholder">
                <div class="maindivupload seenpdf" *ngFor="let data1 of treatmentData?.reports">
                  <h6 style="cursor: pointer;" (click)="download(data1?.url,data1?.filename); openPdfFile(data1?.url)">{{data1?.filename}}</h6>
                  <div class="view">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="37" viewBox="0 0 32 37" fill="none">
                      <path
                        d="M8.70157 0.902344H23.5579L30.9861 7.48891V31.0132V33.3961H27.8026H8.70157H5.51807V30.1467V4.15172V0.902344H8.70157Z"
                        stroke="#BFC3CE" stroke-width="0.439104" stroke-linecap="round" stroke-linejoin="round" />
                      <rect x="13.0374" y="13.1199" width="10.0994" height="2.63463" stroke="#BFC3CE"
                        stroke-width="0.439104" />
                      <path d="M23.4204 0.902344V7.6159H30.8799" stroke="#BFC3CE" stroke-width="0.439104"
                        stroke-linecap="round" stroke-linejoin="round" />
                      <line x1="12.9775" y1="23.0597" x2="18.1992" y2="23.0597" stroke="#BFC3CE"
                        stroke-width="0.439104" />
                      <line x1="12.9775" y1="18.5851" x2="18.1992" y2="18.5851" stroke="#BFC3CE"
                        stroke-width="0.439104" />
                      <line x1="12.9775" y1="20.8234" x2="21.9289" y2="20.8234" stroke="#BFC3CE"
                        stroke-width="0.439104" />
                      <rect x="0.0839844" y="26.0762" width="19.7597" height="10.0994" rx="0.439104" fill="#D8615B" />
                      <path
                        d="M4.72326 32.832V29.1172H6.33565C6.74314 29.1172 7.05754 29.2191 7.27885 29.4228C7.50016 29.6266 7.61081 29.9111 7.61081 30.2764C7.61081 30.6418 7.50016 30.9281 7.27885 31.1353C7.05754 31.3391 6.74314 31.4409 6.33565 31.4409H5.39773V32.832H4.72326ZM5.39773 30.9088H6.23027C6.71504 30.9088 6.95743 30.698 6.95743 30.2764C6.95743 29.8584 6.71504 29.6494 6.23027 29.6494H5.39773V30.9088ZM8.16063 32.832V29.1172H9.54117C10.1629 29.1172 10.6424 29.277 10.9797 29.5967C11.3204 29.9164 11.4908 30.3748 11.4908 30.972C11.4908 31.5657 11.3204 32.0241 10.9797 32.3473C10.6424 32.6704 10.1629 32.832 9.54117 32.832H8.16063ZM8.83509 32.2735H9.49902C10.3561 32.2735 10.7847 31.8397 10.7847 30.972C10.7847 30.1078 10.3561 29.6757 9.49902 29.6757H8.83509V32.2735ZM12.1743 32.832V29.1172H14.6034V29.6547H12.8488V30.7033H14.4928V31.2407H12.8488V32.832H12.1743Z"
                        fill="white" />
                    </svg>
                    <p>358KB</p>
                  </div>


                </div>
                <span *ngIf="!treatmentData?.reports">No Lab Report Uploaded</span>
              </div>
              <div>
                <p _ngcontent-xdx-c337=""> Note: </p>
                <p _ngcontent-xdx-c337="" class="disclaimer"> Files uploaded by you will be accessible only for your
                  clinic </p>
              </div>
            </div>
          </div> -->
                    </div>
                    <!-- </div> -->
                    <!-- </div> -->
                    <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="drugData.length === 0 && treatmentData?.tentativeDiagnosis == '' && treatmentData?.clinicalExamination == '' && treatmentData?.symptoms == '' && treatmentData?.treatmentNotes == '' && treatmentData?.prescriptionImage == '' && treatmentData?.heartRate == '' && treatmentData?.temperature == '' && treatmentData?.weight == ''">
        <p class="no-Treatment">No Treatment Records</p>
    </div>
</div>