<div class="billingFooter">
  <div class="footer-outer-box">
    <div class="return-bg-box payment">
      <p style="color: white">
        <span>Consultation Fee </span>
      
      </p>
    </div>
  </div>
  <form>
  <div  id="paymentInfo">
    <div class="btn-bg-box space-box">
      <input matInput type="text" class=""  placeholder="Amount"   [(ngModel)]="price"  [ngModelOptions]="{standalone: true}" />
      <div class="select-payment-box">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Method</mat-label>
          <mat-select
            placeholder="Choose Method"
            name="method" [(ngModel)]="method"
          >
            <mat-option value="UPI Scanner"> UPI Scanner</mat-option>
            <mat-option value="Credit Card"> Credit Card</mat-option>
            <mat-option value="Debit Card"> Debit Card</mat-option>
            <mat-option value="Cash"> Cash</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <input class="choose-input" type="file" (change)="onFileChange($event)" multiple />
    
<!-- <button (click)="uploadImages()">Upload Images</button> -->

      <!-- <mat-form-field 
        class="scanner-btn"
        [floatLabel]="'always'"
  
      >
        <mat-label  [(ngModel)]="status"> Status</mat-label>
        <mat-select
        
          placeholder="Select Status"
        >
          <mat-option value="Completed"> Paid</mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
    <div class="CTA">
   
      <button
         class="moveBt"
         style="width: 92px;font-size: medium" (click)="readytosee(data?._id)" > Submit </button>

         <button (click)="goBack()"
         class="moveBt"
         style="width: 75px;font-size: medium" > Back </button>

     </div>
  
  

  </div>
</form>
</div>