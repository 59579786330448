<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>Prescription</h1>

    </div>
    <div style="padding-top: 6px;
    padding-right: 7px;">
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>

  </div>
</div>
<div>
 
  <div class="modalFooter">
<div *ngFor="let data of data?.image">

  <img [src]="data">
</div>
  </div>

</div>